/* Sistema de Grid com 12 colunas
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.colunas{
	width: 100%;
	float: left;
	&.uma		{ width: 6.5%; margin-left: 2% }
	&.duas		{ width: 15.0%; margin-left: 2% }
	&.tres		{ width: 23.5%; margin-left: 2% }
	&.quatro	{ width: 32.0%; margin-left: 2% }
	&.cinco		{ width: 40.5%; margin-left: 2% }
	&.seis		{ width: 49.0%; margin-left: 2% }
	&.sete		{ width: 57.5%; margin-left: 2% }
	&.oito		{ width: 66.0%; margin-left: 2% }
	&.nove		{ width: 74.5%; margin-left: 2% }
	&.dez		{ width: 83.0%; margin-left: 2% }
	&.onze		{ width: 91.5%; margin-left: 2% }
	&.doze		{ width: 100%; margin-left: 0 }
	@include mobile {
		&.mobile-uma		{ width: 6.5%; margin-left: 2% }
		&.mobile-duas		{ width: 15.0%; margin-left: 2% }
		&.mobile-tres		{ width: 23.5%; margin-left: 2% }
		&.mobile-quatro		{ width: 32.0%; margin-left: 2% }
		&.mobile-cinco		{ width: 40.5%; margin-left: 2% }
		&.mobile-seis		{ width: 49.0%; margin-left: 2% }
		&.mobile-sete		{ width: 57.5%; margin-left: 2% }
		&.mobile-oito		{ width: 66.0%; margin-left: 2% }
		&.mobile-nove		{ width: 74.5%; margin-left: 2% }
		&.mobile-dez		{ width: 83.0%; margin-left: 2% }
		&.mobile-onze		{ width: 91.5%; margin-left: 2% }
		&.mobile-doze		{ width: 100%; margin-left: 0 }
	}
	@include phablet {
		&.phablet-uma		{ width: 6.5%; margin-left: 2% }
		&.phablet-duas		{ width: 15.0%; margin-left: 2% }
		&.phablet-tres		{ width: 23.5%; margin-left: 2% }
		&.phablet-quatro	{ width: 32.0%; margin-left: 2% }
		&.phablet-cinco		{ width: 40.5%; margin-left: 2% }
		&.phablet-seis		{ width: 49.0%; margin-left: 2% }
		&.phablet-sete		{ width: 57.5%; margin-left: 2% }
		&.phablet-oito		{ width: 66.0%; margin-left: 2% }
		&.phablet-nove		{ width: 74.5%; margin-left: 2% }
		&.phablet-dez		{ width: 83.0%; margin-left: 2% }
		&.phablet-onze		{ width: 91.5%; margin-left: 2% }
		&.phablet-doze		{ width: 100%; margin-left: 0 }
	}
	@include tablet {
		&.tablet-uma		{ width: 6.5%; margin-left: 2% }
		&.tablet-duas		{ width: 15.0%; margin-left: 2% }
		&.tablet-tres		{ width: 23.5%; margin-left: 2% }
		&.tablet-quatro		{ width: 32.0%; margin-left: 2% }
		&.tablet-cinco		{ width: 40.5%; margin-left: 2% }
		&.tablet-seis		{ width: 49.0%; margin-left: 2% }
		&.tablet-sete		{ width: 57.5%; margin-left: 2% }
		&.tablet-oito		{ width: 66.0%; margin-left: 2% }
		&.tablet-nove		{ width: 74.5%; margin-left: 2% }
		&.tablet-dez		{ width: 83.0%; margin-left: 2% }
		&.tablet-onze		{ width: 91.5%; margin-left: 2% }
		&.tablet-doze		{ width: 100%; margin-left: 0 }
	}
	@include desktop {
		&.desktop-uma		{ width: 6.5%; margin-left: 2% }
		&.desktop-duas		{ width: 15.0%; margin-left: 2% }
		&.desktop-tres		{ width: 23.5%; margin-left: 2% }
		&.desktop-quatro	{ width: 32.0%; margin-left: 2% }
		&.desktop-cinco		{ width: 40.5%; margin-left: 2% }
		&.desktop-seis		{ width: 49.0%; margin-left: 2% }
		&.desktop-sete		{ width: 57.5%; margin-left: 2% }
		&.desktop-oito		{ width: 66.0%; margin-left: 2% }
		&.desktop-nove		{ width: 74.5%; margin-left: 2% }
		&.desktop-dez		{ width: 83.0%; margin-left: 2% }
		&.desktop-onze		{ width: 91.5%; margin-left: 2% }
		&.desktop-doze		{ width: 100%; margin-left: 0 }
	}
	@include desktop-hd {
		&.desktop-hd-uma	{ width: 6.5%; margin-left: 2% }
		&.desktop-hd-duas	{ width: 15.0%; margin-left: 2% }
		&.desktop-hd-tres	{ width: 23.5%; margin-left: 2% }
		&.desktop-hd-quatro	{ width: 32.0%; margin-left: 2% }
		&.desktop-hd-cinco	{ width: 40.5%; margin-left: 2% }
		&.desktop-hd-seis	{ width: 49.0%; margin-left: 2% }
		&.desktop-hd-sete	{ width: 57.5%; margin-left: 2% }
		&.desktop-hd-oito	{ width: 66.0%; margin-left: 2% }
		&.desktop-hd-nove	{ width: 74.5%; margin-left: 2% }
		&.desktop-hd-dez	{ width: 83.0%; margin-left: 2% }
		&.desktop-hd-onze	{ width: 91.5%; margin-left: 2% }
		&.desktop-hd-doze	{ width: 100%; margin-left: 0 }
	}
	@include print {
		&.print-uma	{ width: 6.5%; margin-left: 2% }
		&.print-duas	{ width: 15.0%; margin-left: 2% }
		&.print-tres	{ width: 23.5%; margin-left: 2% }
		&.print-quatro	{ width: 32.0%; margin-left: 2% }
		&.print-cinco	{ width: 40.5%; margin-left: 2% }
		&.print-seis	{ width: 49.0%; margin-left: 2% }
		&.print-sete	{ width: 57.5%; margin-left: 2% }
		&.print-oito	{ width: 66.0%; margin-left: 2% }
		&.print-nove	{ width: 74.5%; margin-left: 2% }
		&.print-dez		{ width: 83.0%; margin-left: 2% }
		&.print-onze	{ width: 91.5%; margin-left: 2% }
		&.print-doze	{ width: 100%; margin-left: 0 }
	}
	&:first-child { margin-left: 0 }
	&.centralizadas { float: none; margin: 0 auto }
}


/* Classes Utilitárias
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.linha, .clf			{ @include clf }
.animado				{ @include animado }
.container {
	@include clf;
	width: 100%;
	margin: 0 auto;
	padding-left: 10px;
	padding-right: 10px;
	@include phablet {
		width: 85%;
		padding-left: 0;
		padding-right: 0;
	}
	@include desktop {
		width: 765px;
	}
	@include desktop-hd {
		width: 950px;
	}
}
.texto-editavel			{ @include texto-editavel }
.img-box				{ @include img-box }
.youtube-box			{ @include youtube-box }
.chartjs-box			{ @include chartjs-box }

.float-left				{ float: left }
.float-right			{ float: right }
.invisivel				{ display: none }
.visivel				{ display: block }
@include mobile {
	.mobile-float-left		{ float: left }
	.mobile-float-right		{ float: right }
	.mobile-float-none		{ float: none }
	.mobile-invisivel		{ display: none }
	.mobile-visivel			{ display: block }
}
@include phablet {
	.phablet-float-left		{ float: left }
	.phablet-float-right	{ float: right }
	.phablet-float-none		{ float: none }
	.phablet-invisivel		{ display: none }
	.phablet-visivel		{ display: block }
}
@include tablet {
	.tablet-float-left		{ float: left }
	.tablet-float-right		{ float: right }
	.tablet-float-none		{ float: none }
	.tablet-invisivel		{ display: none }
	.tablet-visivel			{ display: block }
}
@include desktop {
	.desktop-float-left		{ float: left }
	.desktop-float-right	{ float: right }
	.desktop-float-none		{ float: none }
	.desktop-invisivel		{ display: none }
	.desktop-visivel		{ display: block }
}
@include desktop-hd {
	.desktop-hd-float-left	{ float: left }
	.desktop-hd-float-right	{ float: right }
	.desktop-hd-float-none	{ float: none }
	.desktop-hd-invisivel	{ display: none }
	.desktop-hd-visivel		{ display: block }
}

.full-width				{ width: 100% }
.max-full-width			{ max-width: 100% }

.text-center			{ text-align: center }
.text-ellipsis			{ @include text-ellipsis }
.text-justify			{ text-align: justify }
.text-left				{ text-align: left }
.text-right				{ text-align: right }
.text-uppercase			{ text-transform: uppercase }

.margin-zero			{ margin: 0 }
.padding-zero			{ padding: 0 }

.overflow-hidden		{ overflow: hidden }
.overflow-auto			{ overflow: auto }
.overflow-scroll		{ overflow: scroll }