.texto-editavel{
	line-height: 1.5;
	font-size:$font-size;
	
	@include clf;
	
	p {
		margin-bottom: 10px;
		a{
			color: $color_destaque;
		}
	}
	
	ul{
		padding-left: 0;
		li{
			position: relative;
			margin-bottom: 10px;
			list-style-type: none;
			&:before{
				content: "\f00c";
				margin-right: 5px;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				color: #0d5f04;

				-moz-font-smoothing: antialiased;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
			}
		}
	}
	ol{
		padding-left: 0;
		counter-reset: section;
		li{
			position: relative;
			margin-bottom: 10px;
			list-style-type: none;
			&:before{
				counter-increment: section;
				content: counters(section, ".") " -";
				margin-right: 5px;
				font-weight: bold;
			}
		}
	}
	ol, ul{
		margin-bottom: 10px;
		ol, ul{
			padding-left: 20px;
			padding-top: 10px;
		}
		li{
			list-style-position: inside;
		}
	}
	strong{
		font-weight: bold;
	}
	h1,h2,h3,h4,h5,h6{
		margin-bottom: 20px;
		font-weight: bold;
		&:first-child{
			margin-top: 0;
		}
		b, strong{
			font-weight: bold;
		}
	}
	h1{
		font-size: 24px;
		color: $color_destaque2;
		font-weight: normal;
	}
	h2{
		font-size: 22px;
		color: $color_destaque2;
		line-height: 1.2;
	}
	h3{
		color: $color_destaque2;
		font-size: 20px;
	}
	h4{
		font-size: 18px;
	}
	h5{
		font-size: 16px;
	}
	h6{
		font-size: 16px;
	}
	
	table {
		max-width: 100%;
		margin-top: 40px;
		margin-bottom: 20px;

		@include desktop {
			border-left: 4px solid $color_destaque2;
		}

		tr {
			border-top: solid 1px #d1d1d1;

			&:last-child {
				border-bottom: solid 1px #d1d1d1;
			}

			td {
				padding: 10px 30px;
				border: none;

				@include desktop {
					border-right: solid 25px #efefef;
				}

				em {
					color: $color_destaque2;
					font-style: italic;
				}
			}
		}
	}
	img{
		max-width: 100%;
		height: auto;
	}
	small{
		font-size: 80%;
	}
	hr{
		
	}
	
	.rgb-container{
		.rgb-container-inner{
			max-width: 100%;
			@include mq-max($desktop) {
				width: 80% !important;
				float: none !important;
				margin: 0 auto;
			}
		}
	}
	.rgb-link-lightbox{
		color: $color_destaque;
		text-decoration: underline;
		cursor: pointer;
	}
	.rgb-lightbox{
		display: none;
	}
}

.rgb-lightbox{
	padding: 20px;
	max-width: 1175px;
}