body{
	color: $color;
	font-size: $font-size;
	font-family: $font-family;

	overflow-x: hidden;
}

#fundoFade {
    position: fixed;
    left: 0; right: 0;
    top: 0; bottom: 0;
    height: 100vh;
    background: #000;
    z-index: 9999;
}
#blackFundo {
    position: fixed;
    left: 0; right: 0;
    top: 0; bottom: 0;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    z-index: 150;
    display: none;
}

/* .container-modal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.content-modal {
		position: relative;
		width: 90%;
		padding: 15px;
		border-radius: 15px;
		background: #FFFFFF;

		@include mq-min(450) {
			width: 80%;
		}

		@include mq-min(550) {
			width: 70%;
		}

		@include mq-min(650) {
			width: 60%;
		}

		@include mq-min(750) {
			width: 50%;
		}

		@include desktop {
			width: 520px;
			height: 520px;
		}

		#modalBtnExit {
			position: absolute;
			top: 30px;
			right: 30px;
			width: 55px;
			height: 55px;
			display: flex;
			cursor: pointer;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			background-color: #FFF; 

			img {
				width: 70%;
				height: 70%;
				pointer-events: none;
			}
		}

		.content-image {
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				border-radius: 5px;
			}

		}

	}

} */
