.el-lista-subpaginas-categoria{
	padding: 30px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow-x: hidden;

	@include tablet {
		padding: 60px 0;
	}
	
	.introducao{
		.v1{
			margin-bottom: 20px;
		}
		.texto-editavel{
			margin-bottom: 20px;
		}
	}

	.itens {
		
		@include clf;
		
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			
			li {
				float: left;
				width: 100%;
				margin-bottom: 20px;

				@include phablet {
					width: 48%;
					&:nth-child(n) { margin-left: 4% }
					&:nth-child(2n+1) { margin-left: 0 }
				}

				&:nth-child(1) {

					background: $color_destaque2;
					padding: 30px;

					@include desktop { 
						background: none;
						padding: 0;
					}

					.titulo {
						color: #FFF;
						padding-bottom: 10px;

						@include desktop { 
							color: $color_destaque2; 
							margin-bottom: 20px;
						}

						&::after {
							@include desktop {
								content: '';
								position: absolute;
								border-bottom: solid 1px $color_destaque2;
								left: -100%;
								right: 0;
								bottom: 0;
								width: 200%;	
							}								
						}
					}

					.desc {
						color: #FFF;

						&:hover {
							color: #e8e8e8;
						}
						
						@include desktop { 
							color: $color; 
							&:hover {
								color: $color_destaque2;
							}
						}
					}

					.botao-acao {
						margin-top: 45px;
						
						a {
							padding: 4px 20px;
							border-radius: 50px;
							background: #FFF;
							color: $color_destaque2;

							&:hover {
								background: #ccc;
								color: #FFF;
							}

							@include desktop {
								background: $color_destaque2;
								color: #FFF;

								&:hover {
									background: #c16710;
								}								
							}
						}
					}
				}

				&:nth-child(2) {

					background: #e3e3e3;
					padding: 30px;

					@include desktop { background: none; padding: 0; }

					.titulo {

						color: $color;
						padding-bottom: 10px;
						@include desktop { color: $color_destaque2; margin-bottom: 20px; }

						&::after {
							@include desktop {
								content: '';
								position: absolute;
								border-bottom: solid 1px $color_destaque2;
								left: 0;
								right: -100%;
								bottom: 0;
								width: 200%;	
							}								
						}
					}

					.botao-acao {
						margin-top: 45px;
						a {
							padding: 4px 20px;
							border-radius: 50px;
							background: $color_destaque2;
							color: #FFF;
					
							&:hover {
								background: #c16710;
							}
						}
					}					
				}				
				
				a {
					.img{
						img{
							display: block;
							margin: 0 auto;
							max-width: 100%;
							height: auto;
							border-radius: 10px;
						}
					}				
					.info{
						padding: 20px 0;
						line-height: 1.5;
						min-height: 230px;
						.titulo {
							font-size: 28px;
							text-transform: uppercase;
							position: relative;							
							padding-bottom: 10px;
							.item-icone {
								display: none;
								@include desktop {
									display: inline-block;
									margin-right: 15px;								
								}
							}
						}
						.data{
							display: block;
							margin-bottom: 5px;
							font-size: 13px;
						}
					}
					&:hover .info {
						@include desktop {
							.data{
								color: $color_destaque;
							}
							.desc{
								color: $color_destaque;
							}
						}
					}				
				}
								
			}
		}
	}

	&.itens-maximo-3{
		.itens{
			ul{
				li{
					@include desktop {
						width: 30.66667%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(3n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-4{
		.itens{
			ul{
				li{
					@include desktop {
						width: 22%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(4n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-5{
		.itens{
			ul{
				li{
					@include desktop {
						width: 16.8%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(5n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-6{
		.itens{
			ul{
				li{
					@include desktop {
						width: 13.33333%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(6n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
}