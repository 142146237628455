.el-imagem-expandida{
	overflow: hidden;
	img{
		max-width: 400px;
		margin-left: 50%;
		display: block;
		transform: translate(-50%, 0%);
		@include phablet {
			max-width: 550px;
		}
		@include tablet {
			max-width: 768px;
		}
		@include desktop {
			max-width: 1640px;
		}
		@include desktop-hd {
			max-width: 1920px;
		}
	}
}