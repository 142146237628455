/* Base
–––––––––––––––––––––––––––––––––––––––––––––––––– */
*, *:before, *:after{
	box-sizing: border-box;
}
body{
	font-size: 15px;
	font-family: sans-serif;
	color: #efefef;
	background: #efefef;
}
a{
	color: #222222;
	text-decoration: none;
}


/* Botoes
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.botao,
button,
input[type="submit"],
input[type="reset"],
input[type="button"]{
	display: block;
	padding: 10px 45px;
	color: #555555;
	text-align: center;
	font-size: 14px;
	cursor: pointer;
	border: none;

	&:hover, &:focus{
		color: #333333;
		border-color: #888888;
		outline: 0;
	}
	&.primario{
		color: #FFFFFF;
		background-color: #f07d00;
		border-radius: 100px;
		font-weight: normal;
		&:hover, &:focus{
			color: #FFFFFF;
			background-color: #E55B00;
		}	
	}
}


/* Formularios
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.campo,
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select{
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 16px;
	display: inline-block;
	padding: 10px 30px;
	background-color: #FFFFFF;
	border-radius: 50px;
	border: solid 1px #FFF;
	&[size]{
		height: auto;
	}
	&.focus,
	&:focus{
		border: 1px solid #f07d00;
		outline: 0;
	}
	&.disabled,
	&:disabled{
		background-color: #F1F1F1;
		box-shadow: none;
	}
}
.campo,
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.campo{
	overflow-x: hidden;
	overflow-y: auto;
	box-shadow: none;
}
textarea{
	padding: 15px 30px;
	height: 200px;
	border-radius: 20px !important;
}
input[type="file"]{
	font-size: 14px;
}
label{
	display: inline-block;
	margin-bottom: 5px;
	font-weight: 600;
}
fieldset{
	padding: 15px;
	border: 1px solid #D1D1D1;
	border-radius: 4px;
	background-color: #F1F1F1;
}
legend{
	font-weight: 600;
	padding: 1px 10px;
	color: #FFFFFF;
	background-color: #424242;
	border-radius: 4px;
}
input[type="checkbox"],
input[type="radio"]{
	display: inline;
	vertical-align: middle;
	margin: 4px;
}
label > span{
	display: inline;
	margin-right: 5px;
	font-weight: normal;
}
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	position: relative;
}
.fa-caret-down {
	position: absolute;
	top: 45%;
	right: 20px;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 12px;
	color: #898989;
	z-index: 1;		
}

/* Espacamento
–––––––––––––––––––––––––––––––––––––––––––––––––– */
button,
.botao{
	margin-bottom: 10px;
}
.campo,
input,
textarea,
select,
fieldset {
	margin-bottom: 10px;
}
pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol,
form {
	margin-bottom: 0;
}


/* Code
–––––––––––––––––––––––––––––––––––––––––––––––––– */
code {
	padding: 2px 5px;
	margin: 0 2px;
	font-size: 90%;
	white-space: nowrap;
	background: #F1F1F1;
	border: 1px solid #E1E1E1;
	border-radius: 4px;
}
pre > code{
	display: block;
	padding: 10px 15px;
	white-space: pre;
}
hr {
	margin-top: 10px;
	margin-bottom: 20px;
	border-width: 0;
	border-top: 1px solid #E1E1E1;
}


/* Tabelas
–––––––––––––––––––––––––––––––––––––––––––––––––– */
tr{
	th, td{
		padding: 5px;
		text-align: left;
		border-bottom: 1px solid #E1E1E1;
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			padding-right: 0;
		}
	}
}