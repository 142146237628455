.el-lista-subpaginas{
	padding: 30px 0 10px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	
	@include tablet {
		padding: 50px 0 30px;
	}

	.itens {
		
		@include clf;
		
		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			
			li {
				margin-bottom: 20px;
				background: #eaeaea;
				border-radius: 5px;
				overflow: hidden;

				-webkit-box-shadow: 0px 0px 10px 0px rgba(141,141,141,0.5);
				-moz-box-shadow: 0px 0px 10px 0px rgba(141,141,141,0.5);
				box-shadow: 0px 0px 10px 0px rgba(141,141,141,0.5);

				width: 48%;
				&:nth-child(n) { margin-left: 4% }
				&:nth-child(2n+1) { margin-left: 0 }

				@include desktop {
					width: 100%;
				}
				
				a {

					.img {
						img {
							display: block;
							margin: 0 auto;
							width: 100%;
							height: auto;

							@include desktop {
								height: 114px;
							}
						}
					}

					.info {

						&:hover > .titulo  {
							color: $color_destaque;
						}							

						padding: 20px 0;
						line-height: 1.2;
						
						.titulo {
							font-size: 14px;
							font-weight: bold;
							margin-bottom: 10px;
							color: #3b3b3b;
							text-align: center;

							&:hover {
								color: $color_destaque;
							}
						}

						.data{
							display: block;
							margin-bottom: 5px;
							font-size: 13px;
						}
						.desc{

						}
					}
					&:hover .info{
						.data{
							color: $color_destaque;
						}
						.desc{
							color: $color_destaque;
						}
					}
				}
			}
		}
	}

	&.itens-maximo-3 {
		.itens{
			ul {
				li {
					@include desktop {
						width: 30.66667%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(3n+1) { margin-left: 0 }
					}
					.img {
						img {
							display: block;
							margin: 0 auto;
							width: 100%;
							height: 110px;
							
							@include desktop {
								height: 162px !important;
							}

							@include phablet {
								height: 200px  !important;
							}
							
						}
					}

				}
			}
		}
	}
	&.itens-maximo-4{
		.itens{
			ul{
				li{
					@include desktop {
						width: 22%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(4n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-5{
		.itens{
			ul{
				li{
					@include desktop {
						width: 16.8%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(5n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-6{
		.itens{
			ul{
				li{
					@include desktop {
						width: 13.33333%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(6n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
}