/* RGB Style
-------------------------------------------------- */
@import "rgbstyle/reset";
@import "rgbstyle/rgbstyle-reset";
@import "rgbstyle/rgbstyle-mixins";
@import "rgbstyle/rgbstyle-classes";


/* Plugins
-------------------------------------------------- */
@import "plugins/slick";
@import "plugins/swipebox";
@import "plugins/colorbox";
//@import "plugins/animate";
//@import "plugins/select2";
//@import "plugins/jcrop";


/* Fontes
-------------------------------------------------- */
$font-family: 'Source Sans Pro', sans-serif;
$font-size: 16px;

@import "fontes/fontawesome/fontawesome";
@import "fontes/fontawesome/fa-regular";
@import "fontes/fontawesome/fa-solid";
@import "fontes/fontawesome/fa-brands";
@import "fontes/sourcesanspro";


/* Cores
-------------------------------------------------- */
$color: #757575;
$color_destaque: #FF6600;
$color_destaque2: #f07d00;


/* SCSS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import "scss/body";
@import "scss/a";
@import "scss/form";
@import "scss/texto-editavel";

@import "scss/header";
@import "scss/footer";

@import "scss/v";
@import "scss/breadcrumb";
@import "scss/paginacao";
@import "scss/form-ajax-alerta";

@import "scss/erro-404";

@import "scss/pagina";

@import "scss/contato-enviado";

@import "scss/_whatsapp-flutuante";


/* Elementos
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import "elementos/formulario-comentario";
@import "elementos/formulario-contato";
@import "elementos/formulario-cotacao";
@import "elementos/formulario-trabalhe";
@import "elementos/galeria-arquivos";
@import "elementos/galeria-imagens";
@import "elementos/imagem-container";
@import "elementos/imagem-expandida";
@import "elementos/home-slides";
@import "elementos/home-institucional";
@import "elementos/home-botao-acao";
@import "elementos/itens-destaque";
@import "elementos/lista-subpaginas";
@import "elementos/lista-subpaginas-categoria";
@import "elementos/lista-paginas-fixas";
@import "elementos/mapa";
@import "elementos/texto-audio";
@import "elementos/texto-audio-spotfy";
@import "elementos/texto-imagem-lateral";
@import "elementos/texto-imagem-lateral-vazada";
@import "elementos/texto-imagem-topo";
@import "elementos/texto-livre";
@import "elementos/menu-com-titulo";