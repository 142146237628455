.el-lista-paginas-fixas{
	padding: 30px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include tablet {
		padding: 60px 0;
	}
	.introducao{
		.v1{
			margin-bottom: 20px;
		}
		.texto-editavel{
			margin-bottom: 20px;
		}
	}
	.itens{
		@include clf;
		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			li{
				float: left;
				width: 100%;
				margin-bottom: 20px;
				@include phablet {
					width: 48%;
					&:nth-child(n) { margin-left: 4% }
					&:nth-child(2n+1) { margin-left: 0 }
				}
				a{
					.img{
						img{
							display: block;
							margin: 0 auto;
							max-width: 100%;
							height: auto;
						}
					}
					.info{
						padding: 20px 0;
						line-height: 1.2;
						.titulo{
							font-size: 18px;
							font-weight: bold;
							margin-bottom: 10px;
						}
						.data{
							display: block;
							margin-bottom: 5px;
							font-size: 13px;
						}
						.desc{

						}
					}
					&:hover .info{
						.data{
							color: $color_destaque;
						}
						.desc{
							color: $color_destaque;
						}
					}
				}
			}
		}
	}

	&.itens-maximo-3{
		.itens{
			ul{
				li{
					@include desktop {
						width: 30.66667%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(3n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-4{
		.itens{
			ul{
				li{
					@include desktop {
						width: 22%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(4n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-5{
		.itens{
			ul{
				li{
					@include desktop {
						width: 16.8%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(5n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-6{
		.itens{
			ul{
				li{
					@include desktop {
						width: 13.33333%;
						&:nth-child(n) { margin-left: 4% }
						&:nth-child(6n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
}