/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.#{$fa-css-prefix}-500px:before { content: fa-content($fa-var-500px); }
.#{$fa-css-prefix}-accessible-icon:before { content: fa-content($fa-var-accessible-icon); }
.#{$fa-css-prefix}-accusoft:before { content: fa-content($fa-var-accusoft); }
.#{$fa-css-prefix}-address-book:before { content: fa-content($fa-var-address-book); }
.#{$fa-css-prefix}-address-card:before { content: fa-content($fa-var-address-card); }
.#{$fa-css-prefix}-adjust:before { content: fa-content($fa-var-adjust); }
.#{$fa-css-prefix}-adn:before { content: fa-content($fa-var-adn); }
.#{$fa-css-prefix}-adversal:before { content: fa-content($fa-var-adversal); }
.#{$fa-css-prefix}-affiliatetheme:before { content: fa-content($fa-var-affiliatetheme); }
.#{$fa-css-prefix}-algolia:before { content: fa-content($fa-var-algolia); }
.#{$fa-css-prefix}-align-center:before { content: fa-content($fa-var-align-center); }
.#{$fa-css-prefix}-align-justify:before { content: fa-content($fa-var-align-justify); }
.#{$fa-css-prefix}-align-left:before { content: fa-content($fa-var-align-left); }
.#{$fa-css-prefix}-align-right:before { content: fa-content($fa-var-align-right); }
.#{$fa-css-prefix}-allergies:before { content: fa-content($fa-var-allergies); }
.#{$fa-css-prefix}-amazon:before { content: fa-content($fa-var-amazon); }
.#{$fa-css-prefix}-amazon-pay:before { content: fa-content($fa-var-amazon-pay); }
.#{$fa-css-prefix}-ambulance:before { content: fa-content($fa-var-ambulance); }
.#{$fa-css-prefix}-american-sign-language-interpreting:before { content: fa-content($fa-var-american-sign-language-interpreting); }
.#{$fa-css-prefix}-amilia:before { content: fa-content($fa-var-amilia); }
.#{$fa-css-prefix}-anchor:before { content: fa-content($fa-var-anchor); }
.#{$fa-css-prefix}-android:before { content: fa-content($fa-var-android); }
.#{$fa-css-prefix}-angellist:before { content: fa-content($fa-var-angellist); }
.#{$fa-css-prefix}-angle-double-down:before { content: fa-content($fa-var-angle-double-down); }
.#{$fa-css-prefix}-angle-double-left:before { content: fa-content($fa-var-angle-double-left); }
.#{$fa-css-prefix}-angle-double-right:before { content: fa-content($fa-var-angle-double-right); }
.#{$fa-css-prefix}-angle-double-up:before { content: fa-content($fa-var-angle-double-up); }
.#{$fa-css-prefix}-angle-down:before { content: fa-content($fa-var-angle-down); }
.#{$fa-css-prefix}-angle-left:before { content: fa-content($fa-var-angle-left); }
.#{$fa-css-prefix}-angle-right:before { content: fa-content($fa-var-angle-right); }
.#{$fa-css-prefix}-angle-up:before { content: fa-content($fa-var-angle-up); }
.#{$fa-css-prefix}-angrycreative:before { content: fa-content($fa-var-angrycreative); }
.#{$fa-css-prefix}-angular:before { content: fa-content($fa-var-angular); }
.#{$fa-css-prefix}-app-store:before { content: fa-content($fa-var-app-store); }
.#{$fa-css-prefix}-app-store-ios:before { content: fa-content($fa-var-app-store-ios); }
.#{$fa-css-prefix}-apper:before { content: fa-content($fa-var-apper); }
.#{$fa-css-prefix}-apple:before { content: fa-content($fa-var-apple); }
.#{$fa-css-prefix}-apple-pay:before { content: fa-content($fa-var-apple-pay); }
.#{$fa-css-prefix}-archive:before { content: fa-content($fa-var-archive); }
.#{$fa-css-prefix}-arrow-alt-circle-down:before { content: fa-content($fa-var-arrow-alt-circle-down); }
.#{$fa-css-prefix}-arrow-alt-circle-left:before { content: fa-content($fa-var-arrow-alt-circle-left); }
.#{$fa-css-prefix}-arrow-alt-circle-right:before { content: fa-content($fa-var-arrow-alt-circle-right); }
.#{$fa-css-prefix}-arrow-alt-circle-up:before { content: fa-content($fa-var-arrow-alt-circle-up); }
.#{$fa-css-prefix}-arrow-circle-down:before { content: fa-content($fa-var-arrow-circle-down); }
.#{$fa-css-prefix}-arrow-circle-left:before { content: fa-content($fa-var-arrow-circle-left); }
.#{$fa-css-prefix}-arrow-circle-right:before { content: fa-content($fa-var-arrow-circle-right); }
.#{$fa-css-prefix}-arrow-circle-up:before { content: fa-content($fa-var-arrow-circle-up); }
.#{$fa-css-prefix}-arrow-down:before { content: fa-content($fa-var-arrow-down); }
.#{$fa-css-prefix}-arrow-left:before { content: fa-content($fa-var-arrow-left); }
.#{$fa-css-prefix}-arrow-right:before { content: fa-content($fa-var-arrow-right); }
.#{$fa-css-prefix}-arrow-up:before { content: fa-content($fa-var-arrow-up); }
.#{$fa-css-prefix}-arrows-alt:before { content: fa-content($fa-var-arrows-alt); }
.#{$fa-css-prefix}-arrows-alt-h:before { content: fa-content($fa-var-arrows-alt-h); }
.#{$fa-css-prefix}-arrows-alt-v:before { content: fa-content($fa-var-arrows-alt-v); }
.#{$fa-css-prefix}-assistive-listening-systems:before { content: fa-content($fa-var-assistive-listening-systems); }
.#{$fa-css-prefix}-asterisk:before { content: fa-content($fa-var-asterisk); }
.#{$fa-css-prefix}-asymmetrik:before { content: fa-content($fa-var-asymmetrik); }
.#{$fa-css-prefix}-at:before { content: fa-content($fa-var-at); }
.#{$fa-css-prefix}-audible:before { content: fa-content($fa-var-audible); }
.#{$fa-css-prefix}-audio-description:before { content: fa-content($fa-var-audio-description); }
.#{$fa-css-prefix}-autoprefixer:before { content: fa-content($fa-var-autoprefixer); }
.#{$fa-css-prefix}-avianex:before { content: fa-content($fa-var-avianex); }
.#{$fa-css-prefix}-aviato:before { content: fa-content($fa-var-aviato); }
.#{$fa-css-prefix}-aws:before { content: fa-content($fa-var-aws); }
.#{$fa-css-prefix}-backward:before { content: fa-content($fa-var-backward); }
.#{$fa-css-prefix}-balance-scale:before { content: fa-content($fa-var-balance-scale); }
.#{$fa-css-prefix}-ban:before { content: fa-content($fa-var-ban); }
.#{$fa-css-prefix}-band-aid:before { content: fa-content($fa-var-band-aid); }
.#{$fa-css-prefix}-bandcamp:before { content: fa-content($fa-var-bandcamp); }
.#{$fa-css-prefix}-barcode:before { content: fa-content($fa-var-barcode); }
.#{$fa-css-prefix}-bars:before { content: fa-content($fa-var-bars); }
.#{$fa-css-prefix}-baseball-ball:before { content: fa-content($fa-var-baseball-ball); }
.#{$fa-css-prefix}-basketball-ball:before { content: fa-content($fa-var-basketball-ball); }
.#{$fa-css-prefix}-bath:before { content: fa-content($fa-var-bath); }
.#{$fa-css-prefix}-battery-empty:before { content: fa-content($fa-var-battery-empty); }
.#{$fa-css-prefix}-battery-full:before { content: fa-content($fa-var-battery-full); }
.#{$fa-css-prefix}-battery-half:before { content: fa-content($fa-var-battery-half); }
.#{$fa-css-prefix}-battery-quarter:before { content: fa-content($fa-var-battery-quarter); }
.#{$fa-css-prefix}-battery-three-quarters:before { content: fa-content($fa-var-battery-three-quarters); }
.#{$fa-css-prefix}-bed:before { content: fa-content($fa-var-bed); }
.#{$fa-css-prefix}-beer:before { content: fa-content($fa-var-beer); }
.#{$fa-css-prefix}-behance:before { content: fa-content($fa-var-behance); }
.#{$fa-css-prefix}-behance-square:before { content: fa-content($fa-var-behance-square); }
.#{$fa-css-prefix}-bell:before { content: fa-content($fa-var-bell); }
.#{$fa-css-prefix}-bell-slash:before { content: fa-content($fa-var-bell-slash); }
.#{$fa-css-prefix}-bicycle:before { content: fa-content($fa-var-bicycle); }
.#{$fa-css-prefix}-bimobject:before { content: fa-content($fa-var-bimobject); }
.#{$fa-css-prefix}-binoculars:before { content: fa-content($fa-var-binoculars); }
.#{$fa-css-prefix}-birthday-cake:before { content: fa-content($fa-var-birthday-cake); }
.#{$fa-css-prefix}-bitbucket:before { content: fa-content($fa-var-bitbucket); }
.#{$fa-css-prefix}-bitcoin:before { content: fa-content($fa-var-bitcoin); }
.#{$fa-css-prefix}-bity:before { content: fa-content($fa-var-bity); }
.#{$fa-css-prefix}-black-tie:before { content: fa-content($fa-var-black-tie); }
.#{$fa-css-prefix}-blackberry:before { content: fa-content($fa-var-blackberry); }
.#{$fa-css-prefix}-blind:before { content: fa-content($fa-var-blind); }
.#{$fa-css-prefix}-blogger:before { content: fa-content($fa-var-blogger); }
.#{$fa-css-prefix}-blogger-b:before { content: fa-content($fa-var-blogger-b); }
.#{$fa-css-prefix}-bluetooth:before { content: fa-content($fa-var-bluetooth); }
.#{$fa-css-prefix}-bluetooth-b:before { content: fa-content($fa-var-bluetooth-b); }
.#{$fa-css-prefix}-bold:before { content: fa-content($fa-var-bold); }
.#{$fa-css-prefix}-bolt:before { content: fa-content($fa-var-bolt); }
.#{$fa-css-prefix}-bomb:before { content: fa-content($fa-var-bomb); }
.#{$fa-css-prefix}-book:before { content: fa-content($fa-var-book); }
.#{$fa-css-prefix}-bookmark:before { content: fa-content($fa-var-bookmark); }
.#{$fa-css-prefix}-bowling-ball:before { content: fa-content($fa-var-bowling-ball); }
.#{$fa-css-prefix}-box:before { content: fa-content($fa-var-box); }
.#{$fa-css-prefix}-box-open:before { content: fa-content($fa-var-box-open); }
.#{$fa-css-prefix}-boxes:before { content: fa-content($fa-var-boxes); }
.#{$fa-css-prefix}-braille:before { content: fa-content($fa-var-braille); }
.#{$fa-css-prefix}-briefcase:before { content: fa-content($fa-var-briefcase); }
.#{$fa-css-prefix}-briefcase-medical:before { content: fa-content($fa-var-briefcase-medical); }
.#{$fa-css-prefix}-btc:before { content: fa-content($fa-var-btc); }
.#{$fa-css-prefix}-bug:before { content: fa-content($fa-var-bug); }
.#{$fa-css-prefix}-building:before { content: fa-content($fa-var-building); }
.#{$fa-css-prefix}-bullhorn:before { content: fa-content($fa-var-bullhorn); }
.#{$fa-css-prefix}-bullseye:before { content: fa-content($fa-var-bullseye); }
.#{$fa-css-prefix}-burn:before { content: fa-content($fa-var-burn); }
.#{$fa-css-prefix}-buromobelexperte:before { content: fa-content($fa-var-buromobelexperte); }
.#{$fa-css-prefix}-bus:before { content: fa-content($fa-var-bus); }
.#{$fa-css-prefix}-buysellads:before { content: fa-content($fa-var-buysellads); }
.#{$fa-css-prefix}-calculator:before { content: fa-content($fa-var-calculator); }
.#{$fa-css-prefix}-calendar:before { content: fa-content($fa-var-calendar); }
.#{$fa-css-prefix}-calendar-alt:before { content: fa-content($fa-var-calendar-alt); }
.#{$fa-css-prefix}-calendar-check:before { content: fa-content($fa-var-calendar-check); }
.#{$fa-css-prefix}-calendar-minus:before { content: fa-content($fa-var-calendar-minus); }
.#{$fa-css-prefix}-calendar-plus:before { content: fa-content($fa-var-calendar-plus); }
.#{$fa-css-prefix}-calendar-times:before { content: fa-content($fa-var-calendar-times); }
.#{$fa-css-prefix}-camera:before { content: fa-content($fa-var-camera); }
.#{$fa-css-prefix}-camera-retro:before { content: fa-content($fa-var-camera-retro); }
.#{$fa-css-prefix}-capsules:before { content: fa-content($fa-var-capsules); }
.#{$fa-css-prefix}-car:before { content: fa-content($fa-var-car); }
.#{$fa-css-prefix}-caret-down:before { content: fa-content($fa-var-caret-down); }
.#{$fa-css-prefix}-caret-left:before { content: fa-content($fa-var-caret-left); }
.#{$fa-css-prefix}-caret-right:before { content: fa-content($fa-var-caret-right); }
.#{$fa-css-prefix}-caret-square-down:before { content: fa-content($fa-var-caret-square-down); }
.#{$fa-css-prefix}-caret-square-left:before { content: fa-content($fa-var-caret-square-left); }
.#{$fa-css-prefix}-caret-square-right:before { content: fa-content($fa-var-caret-square-right); }
.#{$fa-css-prefix}-caret-square-up:before { content: fa-content($fa-var-caret-square-up); }
.#{$fa-css-prefix}-caret-up:before { content: fa-content($fa-var-caret-up); }
.#{$fa-css-prefix}-cart-arrow-down:before { content: fa-content($fa-var-cart-arrow-down); }
.#{$fa-css-prefix}-cart-plus:before { content: fa-content($fa-var-cart-plus); }
.#{$fa-css-prefix}-cc-amazon-pay:before { content: fa-content($fa-var-cc-amazon-pay); }
.#{$fa-css-prefix}-cc-amex:before { content: fa-content($fa-var-cc-amex); }
.#{$fa-css-prefix}-cc-apple-pay:before { content: fa-content($fa-var-cc-apple-pay); }
.#{$fa-css-prefix}-cc-diners-club:before { content: fa-content($fa-var-cc-diners-club); }
.#{$fa-css-prefix}-cc-discover:before { content: fa-content($fa-var-cc-discover); }
.#{$fa-css-prefix}-cc-jcb:before { content: fa-content($fa-var-cc-jcb); }
.#{$fa-css-prefix}-cc-mastercard:before { content: fa-content($fa-var-cc-mastercard); }
.#{$fa-css-prefix}-cc-paypal:before { content: fa-content($fa-var-cc-paypal); }
.#{$fa-css-prefix}-cc-stripe:before { content: fa-content($fa-var-cc-stripe); }
.#{$fa-css-prefix}-cc-visa:before { content: fa-content($fa-var-cc-visa); }
.#{$fa-css-prefix}-centercode:before { content: fa-content($fa-var-centercode); }
.#{$fa-css-prefix}-certificate:before { content: fa-content($fa-var-certificate); }
.#{$fa-css-prefix}-chart-area:before { content: fa-content($fa-var-chart-area); }
.#{$fa-css-prefix}-chart-bar:before { content: fa-content($fa-var-chart-bar); }
.#{$fa-css-prefix}-chart-line:before { content: fa-content($fa-var-chart-line); }
.#{$fa-css-prefix}-chart-pie:before { content: fa-content($fa-var-chart-pie); }
.#{$fa-css-prefix}-check:before { content: fa-content($fa-var-check); }
.#{$fa-css-prefix}-check-circle:before { content: fa-content($fa-var-check-circle); }
.#{$fa-css-prefix}-check-square:before { content: fa-content($fa-var-check-square); }
.#{$fa-css-prefix}-chess:before { content: fa-content($fa-var-chess); }
.#{$fa-css-prefix}-chess-bishop:before { content: fa-content($fa-var-chess-bishop); }
.#{$fa-css-prefix}-chess-board:before { content: fa-content($fa-var-chess-board); }
.#{$fa-css-prefix}-chess-king:before { content: fa-content($fa-var-chess-king); }
.#{$fa-css-prefix}-chess-knight:before { content: fa-content($fa-var-chess-knight); }
.#{$fa-css-prefix}-chess-pawn:before { content: fa-content($fa-var-chess-pawn); }
.#{$fa-css-prefix}-chess-queen:before { content: fa-content($fa-var-chess-queen); }
.#{$fa-css-prefix}-chess-rook:before { content: fa-content($fa-var-chess-rook); }
.#{$fa-css-prefix}-chevron-circle-down:before { content: fa-content($fa-var-chevron-circle-down); }
.#{$fa-css-prefix}-chevron-circle-left:before { content: fa-content($fa-var-chevron-circle-left); }
.#{$fa-css-prefix}-chevron-circle-right:before { content: fa-content($fa-var-chevron-circle-right); }
.#{$fa-css-prefix}-chevron-circle-up:before { content: fa-content($fa-var-chevron-circle-up); }
.#{$fa-css-prefix}-chevron-down:before { content: fa-content($fa-var-chevron-down); }
.#{$fa-css-prefix}-chevron-left:before { content: fa-content($fa-var-chevron-left); }
.#{$fa-css-prefix}-chevron-right:before { content: fa-content($fa-var-chevron-right); }
.#{$fa-css-prefix}-chevron-up:before { content: fa-content($fa-var-chevron-up); }
.#{$fa-css-prefix}-child:before { content: fa-content($fa-var-child); }
.#{$fa-css-prefix}-chrome:before { content: fa-content($fa-var-chrome); }
.#{$fa-css-prefix}-circle:before { content: fa-content($fa-var-circle); }
.#{$fa-css-prefix}-circle-notch:before { content: fa-content($fa-var-circle-notch); }
.#{$fa-css-prefix}-clipboard:before { content: fa-content($fa-var-clipboard); }
.#{$fa-css-prefix}-clipboard-check:before { content: fa-content($fa-var-clipboard-check); }
.#{$fa-css-prefix}-clipboard-list:before { content: fa-content($fa-var-clipboard-list); }
.#{$fa-css-prefix}-clock:before { content: fa-content($fa-var-clock); }
.#{$fa-css-prefix}-clone:before { content: fa-content($fa-var-clone); }
.#{$fa-css-prefix}-closed-captioning:before { content: fa-content($fa-var-closed-captioning); }
.#{$fa-css-prefix}-cloud:before { content: fa-content($fa-var-cloud); }
.#{$fa-css-prefix}-cloud-download-alt:before { content: fa-content($fa-var-cloud-download-alt); }
.#{$fa-css-prefix}-cloud-upload-alt:before { content: fa-content($fa-var-cloud-upload-alt); }
.#{$fa-css-prefix}-cloudscale:before { content: fa-content($fa-var-cloudscale); }
.#{$fa-css-prefix}-cloudsmith:before { content: fa-content($fa-var-cloudsmith); }
.#{$fa-css-prefix}-cloudversify:before { content: fa-content($fa-var-cloudversify); }
.#{$fa-css-prefix}-code:before { content: fa-content($fa-var-code); }
.#{$fa-css-prefix}-code-branch:before { content: fa-content($fa-var-code-branch); }
.#{$fa-css-prefix}-codepen:before { content: fa-content($fa-var-codepen); }
.#{$fa-css-prefix}-codiepie:before { content: fa-content($fa-var-codiepie); }
.#{$fa-css-prefix}-coffee:before { content: fa-content($fa-var-coffee); }
.#{$fa-css-prefix}-cog:before { content: fa-content($fa-var-cog); }
.#{$fa-css-prefix}-cogs:before { content: fa-content($fa-var-cogs); }
.#{$fa-css-prefix}-columns:before { content: fa-content($fa-var-columns); }
.#{$fa-css-prefix}-comment:before { content: fa-content($fa-var-comment); }
.#{$fa-css-prefix}-comment-alt:before { content: fa-content($fa-var-comment-alt); }
.#{$fa-css-prefix}-comment-dots:before { content: fa-content($fa-var-comment-dots); }
.#{$fa-css-prefix}-comment-slash:before { content: fa-content($fa-var-comment-slash); }
.#{$fa-css-prefix}-comments:before { content: fa-content($fa-var-comments); }
.#{$fa-css-prefix}-compass:before { content: fa-content($fa-var-compass); }
.#{$fa-css-prefix}-compress:before { content: fa-content($fa-var-compress); }
.#{$fa-css-prefix}-connectdevelop:before { content: fa-content($fa-var-connectdevelop); }
.#{$fa-css-prefix}-contao:before { content: fa-content($fa-var-contao); }
.#{$fa-css-prefix}-copy:before { content: fa-content($fa-var-copy); }
.#{$fa-css-prefix}-copyright:before { content: fa-content($fa-var-copyright); }
.#{$fa-css-prefix}-couch:before { content: fa-content($fa-var-couch); }
.#{$fa-css-prefix}-cpanel:before { content: fa-content($fa-var-cpanel); }
.#{$fa-css-prefix}-creative-commons:before { content: fa-content($fa-var-creative-commons); }
.#{$fa-css-prefix}-credit-card:before { content: fa-content($fa-var-credit-card); }
.#{$fa-css-prefix}-crop:before { content: fa-content($fa-var-crop); }
.#{$fa-css-prefix}-crosshairs:before { content: fa-content($fa-var-crosshairs); }
.#{$fa-css-prefix}-css3:before { content: fa-content($fa-var-css3); }
.#{$fa-css-prefix}-css3-alt:before { content: fa-content($fa-var-css3-alt); }
.#{$fa-css-prefix}-cube:before { content: fa-content($fa-var-cube); }
.#{$fa-css-prefix}-cubes:before { content: fa-content($fa-var-cubes); }
.#{$fa-css-prefix}-cut:before { content: fa-content($fa-var-cut); }
.#{$fa-css-prefix}-cuttlefish:before { content: fa-content($fa-var-cuttlefish); }
.#{$fa-css-prefix}-d-and-d:before { content: fa-content($fa-var-d-and-d); }
.#{$fa-css-prefix}-dashcube:before { content: fa-content($fa-var-dashcube); }
.#{$fa-css-prefix}-database:before { content: fa-content($fa-var-database); }
.#{$fa-css-prefix}-deaf:before { content: fa-content($fa-var-deaf); }
.#{$fa-css-prefix}-delicious:before { content: fa-content($fa-var-delicious); }
.#{$fa-css-prefix}-deploydog:before { content: fa-content($fa-var-deploydog); }
.#{$fa-css-prefix}-deskpro:before { content: fa-content($fa-var-deskpro); }
.#{$fa-css-prefix}-desktop:before { content: fa-content($fa-var-desktop); }
.#{$fa-css-prefix}-deviantart:before { content: fa-content($fa-var-deviantart); }
.#{$fa-css-prefix}-diagnoses:before { content: fa-content($fa-var-diagnoses); }
.#{$fa-css-prefix}-digg:before { content: fa-content($fa-var-digg); }
.#{$fa-css-prefix}-digital-ocean:before { content: fa-content($fa-var-digital-ocean); }
.#{$fa-css-prefix}-discord:before { content: fa-content($fa-var-discord); }
.#{$fa-css-prefix}-discourse:before { content: fa-content($fa-var-discourse); }
.#{$fa-css-prefix}-dna:before { content: fa-content($fa-var-dna); }
.#{$fa-css-prefix}-dochub:before { content: fa-content($fa-var-dochub); }
.#{$fa-css-prefix}-docker:before { content: fa-content($fa-var-docker); }
.#{$fa-css-prefix}-dollar-sign:before { content: fa-content($fa-var-dollar-sign); }
.#{$fa-css-prefix}-dolly:before { content: fa-content($fa-var-dolly); }
.#{$fa-css-prefix}-dolly-flatbed:before { content: fa-content($fa-var-dolly-flatbed); }
.#{$fa-css-prefix}-donate:before { content: fa-content($fa-var-donate); }
.#{$fa-css-prefix}-dot-circle:before { content: fa-content($fa-var-dot-circle); }
.#{$fa-css-prefix}-dove:before { content: fa-content($fa-var-dove); }
.#{$fa-css-prefix}-download:before { content: fa-content($fa-var-download); }
.#{$fa-css-prefix}-draft2digital:before { content: fa-content($fa-var-draft2digital); }
.#{$fa-css-prefix}-dribbble:before { content: fa-content($fa-var-dribbble); }
.#{$fa-css-prefix}-dribbble-square:before { content: fa-content($fa-var-dribbble-square); }
.#{$fa-css-prefix}-dropbox:before { content: fa-content($fa-var-dropbox); }
.#{$fa-css-prefix}-drupal:before { content: fa-content($fa-var-drupal); }
.#{$fa-css-prefix}-dyalog:before { content: fa-content($fa-var-dyalog); }
.#{$fa-css-prefix}-earlybirds:before { content: fa-content($fa-var-earlybirds); }
.#{$fa-css-prefix}-edge:before { content: fa-content($fa-var-edge); }
.#{$fa-css-prefix}-edit:before { content: fa-content($fa-var-edit); }
.#{$fa-css-prefix}-eject:before { content: fa-content($fa-var-eject); }
.#{$fa-css-prefix}-elementor:before { content: fa-content($fa-var-elementor); }
.#{$fa-css-prefix}-ellipsis-h:before { content: fa-content($fa-var-ellipsis-h); }
.#{$fa-css-prefix}-ellipsis-v:before { content: fa-content($fa-var-ellipsis-v); }
.#{$fa-css-prefix}-ember:before { content: fa-content($fa-var-ember); }
.#{$fa-css-prefix}-empire:before { content: fa-content($fa-var-empire); }
.#{$fa-css-prefix}-envelope:before { content: fa-content($fa-var-envelope); }
.#{$fa-css-prefix}-envelope-open:before { content: fa-content($fa-var-envelope-open); }
.#{$fa-css-prefix}-envelope-square:before { content: fa-content($fa-var-envelope-square); }
.#{$fa-css-prefix}-envira:before { content: fa-content($fa-var-envira); }
.#{$fa-css-prefix}-eraser:before { content: fa-content($fa-var-eraser); }
.#{$fa-css-prefix}-erlang:before { content: fa-content($fa-var-erlang); }
.#{$fa-css-prefix}-ethereum:before { content: fa-content($fa-var-ethereum); }
.#{$fa-css-prefix}-etsy:before { content: fa-content($fa-var-etsy); }
.#{$fa-css-prefix}-euro-sign:before { content: fa-content($fa-var-euro-sign); }
.#{$fa-css-prefix}-exchange-alt:before { content: fa-content($fa-var-exchange-alt); }
.#{$fa-css-prefix}-exclamation:before { content: fa-content($fa-var-exclamation); }
.#{$fa-css-prefix}-exclamation-circle:before { content: fa-content($fa-var-exclamation-circle); }
.#{$fa-css-prefix}-exclamation-triangle:before { content: fa-content($fa-var-exclamation-triangle); }
.#{$fa-css-prefix}-expand:before { content: fa-content($fa-var-expand); }
.#{$fa-css-prefix}-expand-arrows-alt:before { content: fa-content($fa-var-expand-arrows-alt); }
.#{$fa-css-prefix}-expeditedssl:before { content: fa-content($fa-var-expeditedssl); }
.#{$fa-css-prefix}-external-link-alt:before { content: fa-content($fa-var-external-link-alt); }
.#{$fa-css-prefix}-external-link-square-alt:before { content: fa-content($fa-var-external-link-square-alt); }
.#{$fa-css-prefix}-eye:before { content: fa-content($fa-var-eye); }
.#{$fa-css-prefix}-eye-dropper:before { content: fa-content($fa-var-eye-dropper); }
.#{$fa-css-prefix}-eye-slash:before { content: fa-content($fa-var-eye-slash); }
.#{$fa-css-prefix}-facebook:before { content: fa-content($fa-var-facebook); }
.#{$fa-css-prefix}-facebook-f:before { content: fa-content($fa-var-facebook-f); }
.#{$fa-css-prefix}-facebook-messenger:before { content: fa-content($fa-var-facebook-messenger); }
.#{$fa-css-prefix}-facebook-square:before { content: fa-content($fa-var-facebook-square); }
.#{$fa-css-prefix}-fast-backward:before { content: fa-content($fa-var-fast-backward); }
.#{$fa-css-prefix}-fast-forward:before { content: fa-content($fa-var-fast-forward); }
.#{$fa-css-prefix}-fax:before { content: fa-content($fa-var-fax); }
.#{$fa-css-prefix}-female:before { content: fa-content($fa-var-female); }
.#{$fa-css-prefix}-fighter-jet:before { content: fa-content($fa-var-fighter-jet); }
.#{$fa-css-prefix}-file:before { content: fa-content($fa-var-file); }
.#{$fa-css-prefix}-file-alt:before { content: fa-content($fa-var-file-alt); }
.#{$fa-css-prefix}-file-archive:before { content: fa-content($fa-var-file-archive); }
.#{$fa-css-prefix}-file-audio:before { content: fa-content($fa-var-file-audio); }
.#{$fa-css-prefix}-file-code:before { content: fa-content($fa-var-file-code); }
.#{$fa-css-prefix}-file-excel:before { content: fa-content($fa-var-file-excel); }
.#{$fa-css-prefix}-file-image:before { content: fa-content($fa-var-file-image); }
.#{$fa-css-prefix}-file-medical:before { content: fa-content($fa-var-file-medical); }
.#{$fa-css-prefix}-file-medical-alt:before { content: fa-content($fa-var-file-medical-alt); }
.#{$fa-css-prefix}-file-pdf:before { content: fa-content($fa-var-file-pdf); }
.#{$fa-css-prefix}-file-powerpoint:before { content: fa-content($fa-var-file-powerpoint); }
.#{$fa-css-prefix}-file-video:before { content: fa-content($fa-var-file-video); }
.#{$fa-css-prefix}-file-word:before { content: fa-content($fa-var-file-word); }
.#{$fa-css-prefix}-film:before { content: fa-content($fa-var-film); }
.#{$fa-css-prefix}-filter:before { content: fa-content($fa-var-filter); }
.#{$fa-css-prefix}-fire:before { content: fa-content($fa-var-fire); }
.#{$fa-css-prefix}-fire-extinguisher:before { content: fa-content($fa-var-fire-extinguisher); }
.#{$fa-css-prefix}-firefox:before { content: fa-content($fa-var-firefox); }
.#{$fa-css-prefix}-first-aid:before { content: fa-content($fa-var-first-aid); }
.#{$fa-css-prefix}-first-order:before { content: fa-content($fa-var-first-order); }
.#{$fa-css-prefix}-firstdraft:before { content: fa-content($fa-var-firstdraft); }
.#{$fa-css-prefix}-flag:before { content: fa-content($fa-var-flag); }
.#{$fa-css-prefix}-flag-checkered:before { content: fa-content($fa-var-flag-checkered); }
.#{$fa-css-prefix}-flask:before { content: fa-content($fa-var-flask); }
.#{$fa-css-prefix}-flickr:before { content: fa-content($fa-var-flickr); }
.#{$fa-css-prefix}-flipboard:before { content: fa-content($fa-var-flipboard); }
.#{$fa-css-prefix}-fly:before { content: fa-content($fa-var-fly); }
.#{$fa-css-prefix}-folder:before { content: fa-content($fa-var-folder); }
.#{$fa-css-prefix}-folder-open:before { content: fa-content($fa-var-folder-open); }
.#{$fa-css-prefix}-font:before { content: fa-content($fa-var-font); }
.#{$fa-css-prefix}-font-awesome:before { content: fa-content($fa-var-font-awesome); }
.#{$fa-css-prefix}-font-awesome-alt:before { content: fa-content($fa-var-font-awesome-alt); }
.#{$fa-css-prefix}-font-awesome-flag:before { content: fa-content($fa-var-font-awesome-flag); }
.#{$fa-css-prefix}-fonticons:before { content: fa-content($fa-var-fonticons); }
.#{$fa-css-prefix}-fonticons-fi:before { content: fa-content($fa-var-fonticons-fi); }
.#{$fa-css-prefix}-football-ball:before { content: fa-content($fa-var-football-ball); }
.#{$fa-css-prefix}-fort-awesome:before { content: fa-content($fa-var-fort-awesome); }
.#{$fa-css-prefix}-fort-awesome-alt:before { content: fa-content($fa-var-fort-awesome-alt); }
.#{$fa-css-prefix}-forumbee:before { content: fa-content($fa-var-forumbee); }
.#{$fa-css-prefix}-forward:before { content: fa-content($fa-var-forward); }
.#{$fa-css-prefix}-foursquare:before { content: fa-content($fa-var-foursquare); }
.#{$fa-css-prefix}-free-code-camp:before { content: fa-content($fa-var-free-code-camp); }
.#{$fa-css-prefix}-freebsd:before { content: fa-content($fa-var-freebsd); }
.#{$fa-css-prefix}-frown:before { content: fa-content($fa-var-frown); }
.#{$fa-css-prefix}-futbol:before { content: fa-content($fa-var-futbol); }
.#{$fa-css-prefix}-gamepad:before { content: fa-content($fa-var-gamepad); }
.#{$fa-css-prefix}-gavel:before { content: fa-content($fa-var-gavel); }
.#{$fa-css-prefix}-gem:before { content: fa-content($fa-var-gem); }
.#{$fa-css-prefix}-genderless:before { content: fa-content($fa-var-genderless); }
.#{$fa-css-prefix}-get-pocket:before { content: fa-content($fa-var-get-pocket); }
.#{$fa-css-prefix}-gg:before { content: fa-content($fa-var-gg); }
.#{$fa-css-prefix}-gg-circle:before { content: fa-content($fa-var-gg-circle); }
.#{$fa-css-prefix}-gift:before { content: fa-content($fa-var-gift); }
.#{$fa-css-prefix}-git:before { content: fa-content($fa-var-git); }
.#{$fa-css-prefix}-git-square:before { content: fa-content($fa-var-git-square); }
.#{$fa-css-prefix}-github:before { content: fa-content($fa-var-github); }
.#{$fa-css-prefix}-github-alt:before { content: fa-content($fa-var-github-alt); }
.#{$fa-css-prefix}-github-square:before { content: fa-content($fa-var-github-square); }
.#{$fa-css-prefix}-gitkraken:before { content: fa-content($fa-var-gitkraken); }
.#{$fa-css-prefix}-gitlab:before { content: fa-content($fa-var-gitlab); }
.#{$fa-css-prefix}-gitter:before { content: fa-content($fa-var-gitter); }
.#{$fa-css-prefix}-glass-martini:before { content: fa-content($fa-var-glass-martini); }
.#{$fa-css-prefix}-glide:before { content: fa-content($fa-var-glide); }
.#{$fa-css-prefix}-glide-g:before { content: fa-content($fa-var-glide-g); }
.#{$fa-css-prefix}-globe:before { content: fa-content($fa-var-globe); }
.#{$fa-css-prefix}-gofore:before { content: fa-content($fa-var-gofore); }
.#{$fa-css-prefix}-golf-ball:before { content: fa-content($fa-var-golf-ball); }
.#{$fa-css-prefix}-goodreads:before { content: fa-content($fa-var-goodreads); }
.#{$fa-css-prefix}-goodreads-g:before { content: fa-content($fa-var-goodreads-g); }
.#{$fa-css-prefix}-google:before { content: fa-content($fa-var-google); }
.#{$fa-css-prefix}-google-drive:before { content: fa-content($fa-var-google-drive); }
.#{$fa-css-prefix}-google-play:before { content: fa-content($fa-var-google-play); }
.#{$fa-css-prefix}-google-plus:before { content: fa-content($fa-var-google-plus); }
.#{$fa-css-prefix}-google-plus-g:before { content: fa-content($fa-var-google-plus-g); }
.#{$fa-css-prefix}-google-plus-square:before { content: fa-content($fa-var-google-plus-square); }
.#{$fa-css-prefix}-google-wallet:before { content: fa-content($fa-var-google-wallet); }
.#{$fa-css-prefix}-graduation-cap:before { content: fa-content($fa-var-graduation-cap); }
.#{$fa-css-prefix}-gratipay:before { content: fa-content($fa-var-gratipay); }
.#{$fa-css-prefix}-grav:before { content: fa-content($fa-var-grav); }
.#{$fa-css-prefix}-gripfire:before { content: fa-content($fa-var-gripfire); }
.#{$fa-css-prefix}-grunt:before { content: fa-content($fa-var-grunt); }
.#{$fa-css-prefix}-gulp:before { content: fa-content($fa-var-gulp); }
.#{$fa-css-prefix}-h-square:before { content: fa-content($fa-var-h-square); }
.#{$fa-css-prefix}-hacker-news:before { content: fa-content($fa-var-hacker-news); }
.#{$fa-css-prefix}-hacker-news-square:before { content: fa-content($fa-var-hacker-news-square); }
.#{$fa-css-prefix}-hand-holding:before { content: fa-content($fa-var-hand-holding); }
.#{$fa-css-prefix}-hand-holding-heart:before { content: fa-content($fa-var-hand-holding-heart); }
.#{$fa-css-prefix}-hand-holding-usd:before { content: fa-content($fa-var-hand-holding-usd); }
.#{$fa-css-prefix}-hand-lizard:before { content: fa-content($fa-var-hand-lizard); }
.#{$fa-css-prefix}-hand-paper:before { content: fa-content($fa-var-hand-paper); }
.#{$fa-css-prefix}-hand-peace:before { content: fa-content($fa-var-hand-peace); }
.#{$fa-css-prefix}-hand-point-down:before { content: fa-content($fa-var-hand-point-down); }
.#{$fa-css-prefix}-hand-point-left:before { content: fa-content($fa-var-hand-point-left); }
.#{$fa-css-prefix}-hand-point-right:before { content: fa-content($fa-var-hand-point-right); }
.#{$fa-css-prefix}-hand-point-up:before { content: fa-content($fa-var-hand-point-up); }
.#{$fa-css-prefix}-hand-pointer:before { content: fa-content($fa-var-hand-pointer); }
.#{$fa-css-prefix}-hand-rock:before { content: fa-content($fa-var-hand-rock); }
.#{$fa-css-prefix}-hand-scissors:before { content: fa-content($fa-var-hand-scissors); }
.#{$fa-css-prefix}-hand-spock:before { content: fa-content($fa-var-hand-spock); }
.#{$fa-css-prefix}-hands:before { content: fa-content($fa-var-hands); }
.#{$fa-css-prefix}-hands-helping:before { content: fa-content($fa-var-hands-helping); }
.#{$fa-css-prefix}-handshake:before { content: fa-content($fa-var-handshake); }
.#{$fa-css-prefix}-hashtag:before { content: fa-content($fa-var-hashtag); }
.#{$fa-css-prefix}-hdd:before { content: fa-content($fa-var-hdd); }
.#{$fa-css-prefix}-heading:before { content: fa-content($fa-var-heading); }
.#{$fa-css-prefix}-headphones:before { content: fa-content($fa-var-headphones); }
.#{$fa-css-prefix}-heart:before { content: fa-content($fa-var-heart); }
.#{$fa-css-prefix}-heartbeat:before { content: fa-content($fa-var-heartbeat); }
.#{$fa-css-prefix}-hips:before { content: fa-content($fa-var-hips); }
.#{$fa-css-prefix}-hire-a-helper:before { content: fa-content($fa-var-hire-a-helper); }
.#{$fa-css-prefix}-history:before { content: fa-content($fa-var-history); }
.#{$fa-css-prefix}-hockey-puck:before { content: fa-content($fa-var-hockey-puck); }
.#{$fa-css-prefix}-home:before { content: fa-content($fa-var-home); }
.#{$fa-css-prefix}-hooli:before { content: fa-content($fa-var-hooli); }
.#{$fa-css-prefix}-hospital:before { content: fa-content($fa-var-hospital); }
.#{$fa-css-prefix}-hospital-alt:before { content: fa-content($fa-var-hospital-alt); }
.#{$fa-css-prefix}-hospital-symbol:before { content: fa-content($fa-var-hospital-symbol); }
.#{$fa-css-prefix}-hotjar:before { content: fa-content($fa-var-hotjar); }
.#{$fa-css-prefix}-hourglass:before { content: fa-content($fa-var-hourglass); }
.#{$fa-css-prefix}-hourglass-end:before { content: fa-content($fa-var-hourglass-end); }
.#{$fa-css-prefix}-hourglass-half:before { content: fa-content($fa-var-hourglass-half); }
.#{$fa-css-prefix}-hourglass-start:before { content: fa-content($fa-var-hourglass-start); }
.#{$fa-css-prefix}-houzz:before { content: fa-content($fa-var-houzz); }
.#{$fa-css-prefix}-html5:before { content: fa-content($fa-var-html5); }
.#{$fa-css-prefix}-hubspot:before { content: fa-content($fa-var-hubspot); }
.#{$fa-css-prefix}-i-cursor:before { content: fa-content($fa-var-i-cursor); }
.#{$fa-css-prefix}-id-badge:before { content: fa-content($fa-var-id-badge); }
.#{$fa-css-prefix}-id-card:before { content: fa-content($fa-var-id-card); }
.#{$fa-css-prefix}-id-card-alt:before { content: fa-content($fa-var-id-card-alt); }
.#{$fa-css-prefix}-image:before { content: fa-content($fa-var-image); }
.#{$fa-css-prefix}-images:before { content: fa-content($fa-var-images); }
.#{$fa-css-prefix}-imdb:before { content: fa-content($fa-var-imdb); }
.#{$fa-css-prefix}-inbox:before { content: fa-content($fa-var-inbox); }
.#{$fa-css-prefix}-indent:before { content: fa-content($fa-var-indent); }
.#{$fa-css-prefix}-industry:before { content: fa-content($fa-var-industry); }
.#{$fa-css-prefix}-info:before { content: fa-content($fa-var-info); }
.#{$fa-css-prefix}-info-circle:before { content: fa-content($fa-var-info-circle); }
.#{$fa-css-prefix}-instagram:before { content: fa-content($fa-var-instagram); }
.#{$fa-css-prefix}-internet-explorer:before { content: fa-content($fa-var-internet-explorer); }
.#{$fa-css-prefix}-ioxhost:before { content: fa-content($fa-var-ioxhost); }
.#{$fa-css-prefix}-italic:before { content: fa-content($fa-var-italic); }
.#{$fa-css-prefix}-itunes:before { content: fa-content($fa-var-itunes); }
.#{$fa-css-prefix}-itunes-note:before { content: fa-content($fa-var-itunes-note); }
.#{$fa-css-prefix}-java:before { content: fa-content($fa-var-java); }
.#{$fa-css-prefix}-jenkins:before { content: fa-content($fa-var-jenkins); }
.#{$fa-css-prefix}-joget:before { content: fa-content($fa-var-joget); }
.#{$fa-css-prefix}-joomla:before { content: fa-content($fa-var-joomla); }
.#{$fa-css-prefix}-js:before { content: fa-content($fa-var-js); }
.#{$fa-css-prefix}-js-square:before { content: fa-content($fa-var-js-square); }
.#{$fa-css-prefix}-jsfiddle:before { content: fa-content($fa-var-jsfiddle); }
.#{$fa-css-prefix}-key:before { content: fa-content($fa-var-key); }
.#{$fa-css-prefix}-keyboard:before { content: fa-content($fa-var-keyboard); }
.#{$fa-css-prefix}-keycdn:before { content: fa-content($fa-var-keycdn); }
.#{$fa-css-prefix}-kickstarter:before { content: fa-content($fa-var-kickstarter); }
.#{$fa-css-prefix}-kickstarter-k:before { content: fa-content($fa-var-kickstarter-k); }
.#{$fa-css-prefix}-korvue:before { content: fa-content($fa-var-korvue); }
.#{$fa-css-prefix}-language:before { content: fa-content($fa-var-language); }
.#{$fa-css-prefix}-laptop:before { content: fa-content($fa-var-laptop); }
.#{$fa-css-prefix}-laravel:before { content: fa-content($fa-var-laravel); }
.#{$fa-css-prefix}-lastfm:before { content: fa-content($fa-var-lastfm); }
.#{$fa-css-prefix}-lastfm-square:before { content: fa-content($fa-var-lastfm-square); }
.#{$fa-css-prefix}-leaf:before { content: fa-content($fa-var-leaf); }
.#{$fa-css-prefix}-leanpub:before { content: fa-content($fa-var-leanpub); }
.#{$fa-css-prefix}-lemon:before { content: fa-content($fa-var-lemon); }
.#{$fa-css-prefix}-less:before { content: fa-content($fa-var-less); }
.#{$fa-css-prefix}-level-down-alt:before { content: fa-content($fa-var-level-down-alt); }
.#{$fa-css-prefix}-level-up-alt:before { content: fa-content($fa-var-level-up-alt); }
.#{$fa-css-prefix}-life-ring:before { content: fa-content($fa-var-life-ring); }
.#{$fa-css-prefix}-lightbulb:before { content: fa-content($fa-var-lightbulb); }
.#{$fa-css-prefix}-line:before { content: fa-content($fa-var-line); }
.#{$fa-css-prefix}-link:before { content: fa-content($fa-var-link); }
.#{$fa-css-prefix}-linkedin:before { content: fa-content($fa-var-linkedin); }
.#{$fa-css-prefix}-linkedin-in:before { content: fa-content($fa-var-linkedin-in); }
.#{$fa-css-prefix}-linode:before { content: fa-content($fa-var-linode); }
.#{$fa-css-prefix}-linux:before { content: fa-content($fa-var-linux); }
.#{$fa-css-prefix}-lira-sign:before { content: fa-content($fa-var-lira-sign); }
.#{$fa-css-prefix}-list:before { content: fa-content($fa-var-list); }
.#{$fa-css-prefix}-list-alt:before { content: fa-content($fa-var-list-alt); }
.#{$fa-css-prefix}-list-ol:before { content: fa-content($fa-var-list-ol); }
.#{$fa-css-prefix}-list-ul:before { content: fa-content($fa-var-list-ul); }
.#{$fa-css-prefix}-location-arrow:before { content: fa-content($fa-var-location-arrow); }
.#{$fa-css-prefix}-lock:before { content: fa-content($fa-var-lock); }
.#{$fa-css-prefix}-lock-open:before { content: fa-content($fa-var-lock-open); }
.#{$fa-css-prefix}-long-arrow-alt-down:before { content: fa-content($fa-var-long-arrow-alt-down); }
.#{$fa-css-prefix}-long-arrow-alt-left:before { content: fa-content($fa-var-long-arrow-alt-left); }
.#{$fa-css-prefix}-long-arrow-alt-right:before { content: fa-content($fa-var-long-arrow-alt-right); }
.#{$fa-css-prefix}-long-arrow-alt-up:before { content: fa-content($fa-var-long-arrow-alt-up); }
.#{$fa-css-prefix}-low-vision:before { content: fa-content($fa-var-low-vision); }
.#{$fa-css-prefix}-lyft:before { content: fa-content($fa-var-lyft); }
.#{$fa-css-prefix}-magento:before { content: fa-content($fa-var-magento); }
.#{$fa-css-prefix}-magic:before { content: fa-content($fa-var-magic); }
.#{$fa-css-prefix}-magnet:before { content: fa-content($fa-var-magnet); }
.#{$fa-css-prefix}-male:before { content: fa-content($fa-var-male); }
.#{$fa-css-prefix}-map:before { content: fa-content($fa-var-map); }
.#{$fa-css-prefix}-map-marker:before { content: fa-content($fa-var-map-marker); }
.#{$fa-css-prefix}-map-marker-alt:before { content: fa-content($fa-var-map-marker-alt); }
.#{$fa-css-prefix}-map-pin:before { content: fa-content($fa-var-map-pin); }
.#{$fa-css-prefix}-map-signs:before { content: fa-content($fa-var-map-signs); }
.#{$fa-css-prefix}-mars:before { content: fa-content($fa-var-mars); }
.#{$fa-css-prefix}-mars-double:before { content: fa-content($fa-var-mars-double); }
.#{$fa-css-prefix}-mars-stroke:before { content: fa-content($fa-var-mars-stroke); }
.#{$fa-css-prefix}-mars-stroke-h:before { content: fa-content($fa-var-mars-stroke-h); }
.#{$fa-css-prefix}-mars-stroke-v:before { content: fa-content($fa-var-mars-stroke-v); }
.#{$fa-css-prefix}-maxcdn:before { content: fa-content($fa-var-maxcdn); }
.#{$fa-css-prefix}-medapps:before { content: fa-content($fa-var-medapps); }
.#{$fa-css-prefix}-medium:before { content: fa-content($fa-var-medium); }
.#{$fa-css-prefix}-medium-m:before { content: fa-content($fa-var-medium-m); }
.#{$fa-css-prefix}-medkit:before { content: fa-content($fa-var-medkit); }
.#{$fa-css-prefix}-medrt:before { content: fa-content($fa-var-medrt); }
.#{$fa-css-prefix}-meetup:before { content: fa-content($fa-var-meetup); }
.#{$fa-css-prefix}-meh:before { content: fa-content($fa-var-meh); }
.#{$fa-css-prefix}-mercury:before { content: fa-content($fa-var-mercury); }
.#{$fa-css-prefix}-microchip:before { content: fa-content($fa-var-microchip); }
.#{$fa-css-prefix}-microphone:before { content: fa-content($fa-var-microphone); }
.#{$fa-css-prefix}-microphone-slash:before { content: fa-content($fa-var-microphone-slash); }
.#{$fa-css-prefix}-microsoft:before { content: fa-content($fa-var-microsoft); }
.#{$fa-css-prefix}-minus:before { content: fa-content($fa-var-minus); }
.#{$fa-css-prefix}-minus-circle:before { content: fa-content($fa-var-minus-circle); }
.#{$fa-css-prefix}-minus-square:before { content: fa-content($fa-var-minus-square); }
.#{$fa-css-prefix}-mix:before { content: fa-content($fa-var-mix); }
.#{$fa-css-prefix}-mixcloud:before { content: fa-content($fa-var-mixcloud); }
.#{$fa-css-prefix}-mizuni:before { content: fa-content($fa-var-mizuni); }
.#{$fa-css-prefix}-mobile:before { content: fa-content($fa-var-mobile); }
.#{$fa-css-prefix}-mobile-alt:before { content: fa-content($fa-var-mobile-alt); }
.#{$fa-css-prefix}-modx:before { content: fa-content($fa-var-modx); }
.#{$fa-css-prefix}-monero:before { content: fa-content($fa-var-monero); }
.#{$fa-css-prefix}-money-bill-alt:before { content: fa-content($fa-var-money-bill-alt); }
.#{$fa-css-prefix}-moon:before { content: fa-content($fa-var-moon); }
.#{$fa-css-prefix}-motorcycle:before { content: fa-content($fa-var-motorcycle); }
.#{$fa-css-prefix}-mouse-pointer:before { content: fa-content($fa-var-mouse-pointer); }
.#{$fa-css-prefix}-music:before { content: fa-content($fa-var-music); }
.#{$fa-css-prefix}-napster:before { content: fa-content($fa-var-napster); }
.#{$fa-css-prefix}-neuter:before { content: fa-content($fa-var-neuter); }
.#{$fa-css-prefix}-newspaper:before { content: fa-content($fa-var-newspaper); }
.#{$fa-css-prefix}-nintendo-switch:before { content: fa-content($fa-var-nintendo-switch); }
.#{$fa-css-prefix}-node:before { content: fa-content($fa-var-node); }
.#{$fa-css-prefix}-node-js:before { content: fa-content($fa-var-node-js); }
.#{$fa-css-prefix}-notes-medical:before { content: fa-content($fa-var-notes-medical); }
.#{$fa-css-prefix}-npm:before { content: fa-content($fa-var-npm); }
.#{$fa-css-prefix}-ns8:before { content: fa-content($fa-var-ns8); }
.#{$fa-css-prefix}-nutritionix:before { content: fa-content($fa-var-nutritionix); }
.#{$fa-css-prefix}-object-group:before { content: fa-content($fa-var-object-group); }
.#{$fa-css-prefix}-object-ungroup:before { content: fa-content($fa-var-object-ungroup); }
.#{$fa-css-prefix}-odnoklassniki:before { content: fa-content($fa-var-odnoklassniki); }
.#{$fa-css-prefix}-odnoklassniki-square:before { content: fa-content($fa-var-odnoklassniki-square); }
.#{$fa-css-prefix}-opencart:before { content: fa-content($fa-var-opencart); }
.#{$fa-css-prefix}-openid:before { content: fa-content($fa-var-openid); }
.#{$fa-css-prefix}-opera:before { content: fa-content($fa-var-opera); }
.#{$fa-css-prefix}-optin-monster:before { content: fa-content($fa-var-optin-monster); }
.#{$fa-css-prefix}-osi:before { content: fa-content($fa-var-osi); }
.#{$fa-css-prefix}-outdent:before { content: fa-content($fa-var-outdent); }
.#{$fa-css-prefix}-page4:before { content: fa-content($fa-var-page4); }
.#{$fa-css-prefix}-pagelines:before { content: fa-content($fa-var-pagelines); }
.#{$fa-css-prefix}-paint-brush:before { content: fa-content($fa-var-paint-brush); }
.#{$fa-css-prefix}-palfed:before { content: fa-content($fa-var-palfed); }
.#{$fa-css-prefix}-pallet:before { content: fa-content($fa-var-pallet); }
.#{$fa-css-prefix}-paper-plane:before { content: fa-content($fa-var-paper-plane); }
.#{$fa-css-prefix}-paperclip:before { content: fa-content($fa-var-paperclip); }
.#{$fa-css-prefix}-parachute-box:before { content: fa-content($fa-var-parachute-box); }
.#{$fa-css-prefix}-paragraph:before { content: fa-content($fa-var-paragraph); }
.#{$fa-css-prefix}-paste:before { content: fa-content($fa-var-paste); }
.#{$fa-css-prefix}-patreon:before { content: fa-content($fa-var-patreon); }
.#{$fa-css-prefix}-pause:before { content: fa-content($fa-var-pause); }
.#{$fa-css-prefix}-pause-circle:before { content: fa-content($fa-var-pause-circle); }
.#{$fa-css-prefix}-paw:before { content: fa-content($fa-var-paw); }
.#{$fa-css-prefix}-paypal:before { content: fa-content($fa-var-paypal); }
.#{$fa-css-prefix}-pen-square:before { content: fa-content($fa-var-pen-square); }
.#{$fa-css-prefix}-pencil-alt:before { content: fa-content($fa-var-pencil-alt); }
.#{$fa-css-prefix}-people-carry:before { content: fa-content($fa-var-people-carry); }
.#{$fa-css-prefix}-percent:before { content: fa-content($fa-var-percent); }
.#{$fa-css-prefix}-periscope:before { content: fa-content($fa-var-periscope); }
.#{$fa-css-prefix}-phabricator:before { content: fa-content($fa-var-phabricator); }
.#{$fa-css-prefix}-phoenix-framework:before { content: fa-content($fa-var-phoenix-framework); }
.#{$fa-css-prefix}-phone:before { content: fa-content($fa-var-phone); }
.#{$fa-css-prefix}-phone-slash:before { content: fa-content($fa-var-phone-slash); }
.#{$fa-css-prefix}-phone-square:before { content: fa-content($fa-var-phone-square); }
.#{$fa-css-prefix}-phone-volume:before { content: fa-content($fa-var-phone-volume); }
.#{$fa-css-prefix}-php:before { content: fa-content($fa-var-php); }
.#{$fa-css-prefix}-pied-piper:before { content: fa-content($fa-var-pied-piper); }
.#{$fa-css-prefix}-pied-piper-alt:before { content: fa-content($fa-var-pied-piper-alt); }
.#{$fa-css-prefix}-pied-piper-hat:before { content: fa-content($fa-var-pied-piper-hat); }
.#{$fa-css-prefix}-pied-piper-pp:before { content: fa-content($fa-var-pied-piper-pp); }
.#{$fa-css-prefix}-piggy-bank:before { content: fa-content($fa-var-piggy-bank); }
.#{$fa-css-prefix}-pills:before { content: fa-content($fa-var-pills); }
.#{$fa-css-prefix}-pinterest:before { content: fa-content($fa-var-pinterest); }
.#{$fa-css-prefix}-pinterest-p:before { content: fa-content($fa-var-pinterest-p); }
.#{$fa-css-prefix}-pinterest-square:before { content: fa-content($fa-var-pinterest-square); }
.#{$fa-css-prefix}-plane:before { content: fa-content($fa-var-plane); }
.#{$fa-css-prefix}-play:before { content: fa-content($fa-var-play); }
.#{$fa-css-prefix}-play-circle:before { content: fa-content($fa-var-play-circle); }
.#{$fa-css-prefix}-playstation:before { content: fa-content($fa-var-playstation); }
.#{$fa-css-prefix}-plug:before { content: fa-content($fa-var-plug); }
.#{$fa-css-prefix}-plus:before { content: fa-content($fa-var-plus); }
.#{$fa-css-prefix}-plus-circle:before { content: fa-content($fa-var-plus-circle); }
.#{$fa-css-prefix}-plus-square:before { content: fa-content($fa-var-plus-square); }
.#{$fa-css-prefix}-podcast:before { content: fa-content($fa-var-podcast); }
.#{$fa-css-prefix}-poo:before { content: fa-content($fa-var-poo); }
.#{$fa-css-prefix}-pound-sign:before { content: fa-content($fa-var-pound-sign); }
.#{$fa-css-prefix}-power-off:before { content: fa-content($fa-var-power-off); }
.#{$fa-css-prefix}-prescription-bottle:before { content: fa-content($fa-var-prescription-bottle); }
.#{$fa-css-prefix}-prescription-bottle-alt:before { content: fa-content($fa-var-prescription-bottle-alt); }
.#{$fa-css-prefix}-print:before { content: fa-content($fa-var-print); }
.#{$fa-css-prefix}-procedures:before { content: fa-content($fa-var-procedures); }
.#{$fa-css-prefix}-product-hunt:before { content: fa-content($fa-var-product-hunt); }
.#{$fa-css-prefix}-pushed:before { content: fa-content($fa-var-pushed); }
.#{$fa-css-prefix}-puzzle-piece:before { content: fa-content($fa-var-puzzle-piece); }
.#{$fa-css-prefix}-python:before { content: fa-content($fa-var-python); }
.#{$fa-css-prefix}-qq:before { content: fa-content($fa-var-qq); }
.#{$fa-css-prefix}-qrcode:before { content: fa-content($fa-var-qrcode); }
.#{$fa-css-prefix}-question:before { content: fa-content($fa-var-question); }
.#{$fa-css-prefix}-question-circle:before { content: fa-content($fa-var-question-circle); }
.#{$fa-css-prefix}-quidditch:before { content: fa-content($fa-var-quidditch); }
.#{$fa-css-prefix}-quinscape:before { content: fa-content($fa-var-quinscape); }
.#{$fa-css-prefix}-quora:before { content: fa-content($fa-var-quora); }
.#{$fa-css-prefix}-quote-left:before { content: fa-content($fa-var-quote-left); }
.#{$fa-css-prefix}-quote-right:before { content: fa-content($fa-var-quote-right); }
.#{$fa-css-prefix}-random:before { content: fa-content($fa-var-random); }
.#{$fa-css-prefix}-ravelry:before { content: fa-content($fa-var-ravelry); }
.#{$fa-css-prefix}-react:before { content: fa-content($fa-var-react); }
.#{$fa-css-prefix}-readme:before { content: fa-content($fa-var-readme); }
.#{$fa-css-prefix}-rebel:before { content: fa-content($fa-var-rebel); }
.#{$fa-css-prefix}-recycle:before { content: fa-content($fa-var-recycle); }
.#{$fa-css-prefix}-red-river:before { content: fa-content($fa-var-red-river); }
.#{$fa-css-prefix}-reddit:before { content: fa-content($fa-var-reddit); }
.#{$fa-css-prefix}-reddit-alien:before { content: fa-content($fa-var-reddit-alien); }
.#{$fa-css-prefix}-reddit-square:before { content: fa-content($fa-var-reddit-square); }
.#{$fa-css-prefix}-redo:before { content: fa-content($fa-var-redo); }
.#{$fa-css-prefix}-redo-alt:before { content: fa-content($fa-var-redo-alt); }
.#{$fa-css-prefix}-registered:before { content: fa-content($fa-var-registered); }
.#{$fa-css-prefix}-rendact:before { content: fa-content($fa-var-rendact); }
.#{$fa-css-prefix}-renren:before { content: fa-content($fa-var-renren); }
.#{$fa-css-prefix}-reply:before { content: fa-content($fa-var-reply); }
.#{$fa-css-prefix}-reply-all:before { content: fa-content($fa-var-reply-all); }
.#{$fa-css-prefix}-replyd:before { content: fa-content($fa-var-replyd); }
.#{$fa-css-prefix}-resolving:before { content: fa-content($fa-var-resolving); }
.#{$fa-css-prefix}-retweet:before { content: fa-content($fa-var-retweet); }
.#{$fa-css-prefix}-ribbon:before { content: fa-content($fa-var-ribbon); }
.#{$fa-css-prefix}-road:before { content: fa-content($fa-var-road); }
.#{$fa-css-prefix}-rocket:before { content: fa-content($fa-var-rocket); }
.#{$fa-css-prefix}-rocketchat:before { content: fa-content($fa-var-rocketchat); }
.#{$fa-css-prefix}-rockrms:before { content: fa-content($fa-var-rockrms); }
.#{$fa-css-prefix}-rss:before { content: fa-content($fa-var-rss); }
.#{$fa-css-prefix}-rss-square:before { content: fa-content($fa-var-rss-square); }
.#{$fa-css-prefix}-ruble-sign:before { content: fa-content($fa-var-ruble-sign); }
.#{$fa-css-prefix}-rupee-sign:before { content: fa-content($fa-var-rupee-sign); }
.#{$fa-css-prefix}-safari:before { content: fa-content($fa-var-safari); }
.#{$fa-css-prefix}-sass:before { content: fa-content($fa-var-sass); }
.#{$fa-css-prefix}-save:before { content: fa-content($fa-var-save); }
.#{$fa-css-prefix}-schlix:before { content: fa-content($fa-var-schlix); }
.#{$fa-css-prefix}-scribd:before { content: fa-content($fa-var-scribd); }
.#{$fa-css-prefix}-search:before { content: fa-content($fa-var-search); }
.#{$fa-css-prefix}-search-minus:before { content: fa-content($fa-var-search-minus); }
.#{$fa-css-prefix}-search-plus:before { content: fa-content($fa-var-search-plus); }
.#{$fa-css-prefix}-searchengin:before { content: fa-content($fa-var-searchengin); }
.#{$fa-css-prefix}-seedling:before { content: fa-content($fa-var-seedling); }
.#{$fa-css-prefix}-sellcast:before { content: fa-content($fa-var-sellcast); }
.#{$fa-css-prefix}-sellsy:before { content: fa-content($fa-var-sellsy); }
.#{$fa-css-prefix}-server:before { content: fa-content($fa-var-server); }
.#{$fa-css-prefix}-servicestack:before { content: fa-content($fa-var-servicestack); }
.#{$fa-css-prefix}-share:before { content: fa-content($fa-var-share); }
.#{$fa-css-prefix}-share-alt:before { content: fa-content($fa-var-share-alt); }
.#{$fa-css-prefix}-share-alt-square:before { content: fa-content($fa-var-share-alt-square); }
.#{$fa-css-prefix}-share-square:before { content: fa-content($fa-var-share-square); }
.#{$fa-css-prefix}-shekel-sign:before { content: fa-content($fa-var-shekel-sign); }
.#{$fa-css-prefix}-shield-alt:before { content: fa-content($fa-var-shield-alt); }
.#{$fa-css-prefix}-ship:before { content: fa-content($fa-var-ship); }
.#{$fa-css-prefix}-shipping-fast:before { content: fa-content($fa-var-shipping-fast); }
.#{$fa-css-prefix}-shirtsinbulk:before { content: fa-content($fa-var-shirtsinbulk); }
.#{$fa-css-prefix}-shopping-bag:before { content: fa-content($fa-var-shopping-bag); }
.#{$fa-css-prefix}-shopping-basket:before { content: fa-content($fa-var-shopping-basket); }
.#{$fa-css-prefix}-shopping-cart:before { content: fa-content($fa-var-shopping-cart); }
.#{$fa-css-prefix}-shower:before { content: fa-content($fa-var-shower); }
.#{$fa-css-prefix}-sign:before { content: fa-content($fa-var-sign); }
.#{$fa-css-prefix}-sign-in-alt:before { content: fa-content($fa-var-sign-in-alt); }
.#{$fa-css-prefix}-sign-language:before { content: fa-content($fa-var-sign-language); }
.#{$fa-css-prefix}-sign-out-alt:before { content: fa-content($fa-var-sign-out-alt); }
.#{$fa-css-prefix}-signal:before { content: fa-content($fa-var-signal); }
.#{$fa-css-prefix}-simplybuilt:before { content: fa-content($fa-var-simplybuilt); }
.#{$fa-css-prefix}-sistrix:before { content: fa-content($fa-var-sistrix); }
.#{$fa-css-prefix}-sitemap:before { content: fa-content($fa-var-sitemap); }
.#{$fa-css-prefix}-skyatlas:before { content: fa-content($fa-var-skyatlas); }
.#{$fa-css-prefix}-skype:before { content: fa-content($fa-var-skype); }
.#{$fa-css-prefix}-slack:before { content: fa-content($fa-var-slack); }
.#{$fa-css-prefix}-slack-hash:before { content: fa-content($fa-var-slack-hash); }
.#{$fa-css-prefix}-sliders-h:before { content: fa-content($fa-var-sliders-h); }
.#{$fa-css-prefix}-slideshare:before { content: fa-content($fa-var-slideshare); }
.#{$fa-css-prefix}-smile:before { content: fa-content($fa-var-smile); }
.#{$fa-css-prefix}-smoking:before { content: fa-content($fa-var-smoking); }
.#{$fa-css-prefix}-snapchat:before { content: fa-content($fa-var-snapchat); }
.#{$fa-css-prefix}-snapchat-ghost:before { content: fa-content($fa-var-snapchat-ghost); }
.#{$fa-css-prefix}-snapchat-square:before { content: fa-content($fa-var-snapchat-square); }
.#{$fa-css-prefix}-snowflake:before { content: fa-content($fa-var-snowflake); }
.#{$fa-css-prefix}-sort:before { content: fa-content($fa-var-sort); }
.#{$fa-css-prefix}-sort-alpha-down:before { content: fa-content($fa-var-sort-alpha-down); }
.#{$fa-css-prefix}-sort-alpha-up:before { content: fa-content($fa-var-sort-alpha-up); }
.#{$fa-css-prefix}-sort-amount-down:before { content: fa-content($fa-var-sort-amount-down); }
.#{$fa-css-prefix}-sort-amount-up:before { content: fa-content($fa-var-sort-amount-up); }
.#{$fa-css-prefix}-sort-down:before { content: fa-content($fa-var-sort-down); }
.#{$fa-css-prefix}-sort-numeric-down:before { content: fa-content($fa-var-sort-numeric-down); }
.#{$fa-css-prefix}-sort-numeric-up:before { content: fa-content($fa-var-sort-numeric-up); }
.#{$fa-css-prefix}-sort-up:before { content: fa-content($fa-var-sort-up); }
.#{$fa-css-prefix}-soundcloud:before { content: fa-content($fa-var-soundcloud); }
.#{$fa-css-prefix}-space-shuttle:before { content: fa-content($fa-var-space-shuttle); }
.#{$fa-css-prefix}-speakap:before { content: fa-content($fa-var-speakap); }
.#{$fa-css-prefix}-spinner:before { content: fa-content($fa-var-spinner); }
.#{$fa-css-prefix}-spotify:before { content: fa-content($fa-var-spotify); }
.#{$fa-css-prefix}-square:before { content: fa-content($fa-var-square); }
.#{$fa-css-prefix}-square-full:before { content: fa-content($fa-var-square-full); }
.#{$fa-css-prefix}-stack-exchange:before { content: fa-content($fa-var-stack-exchange); }
.#{$fa-css-prefix}-stack-overflow:before { content: fa-content($fa-var-stack-overflow); }
.#{$fa-css-prefix}-star:before { content: fa-content($fa-var-star); }
.#{$fa-css-prefix}-star-half:before { content: fa-content($fa-var-star-half); }
.#{$fa-css-prefix}-staylinked:before { content: fa-content($fa-var-staylinked); }
.#{$fa-css-prefix}-steam:before { content: fa-content($fa-var-steam); }
.#{$fa-css-prefix}-steam-square:before { content: fa-content($fa-var-steam-square); }
.#{$fa-css-prefix}-steam-symbol:before { content: fa-content($fa-var-steam-symbol); }
.#{$fa-css-prefix}-step-backward:before { content: fa-content($fa-var-step-backward); }
.#{$fa-css-prefix}-step-forward:before { content: fa-content($fa-var-step-forward); }
.#{$fa-css-prefix}-stethoscope:before { content: fa-content($fa-var-stethoscope); }
.#{$fa-css-prefix}-sticker-mule:before { content: fa-content($fa-var-sticker-mule); }
.#{$fa-css-prefix}-sticky-note:before { content: fa-content($fa-var-sticky-note); }
.#{$fa-css-prefix}-stop:before { content: fa-content($fa-var-stop); }
.#{$fa-css-prefix}-stop-circle:before { content: fa-content($fa-var-stop-circle); }
.#{$fa-css-prefix}-stopwatch:before { content: fa-content($fa-var-stopwatch); }
.#{$fa-css-prefix}-strava:before { content: fa-content($fa-var-strava); }
.#{$fa-css-prefix}-street-view:before { content: fa-content($fa-var-street-view); }
.#{$fa-css-prefix}-strikethrough:before { content: fa-content($fa-var-strikethrough); }
.#{$fa-css-prefix}-stripe:before { content: fa-content($fa-var-stripe); }
.#{$fa-css-prefix}-stripe-s:before { content: fa-content($fa-var-stripe-s); }
.#{$fa-css-prefix}-studiovinari:before { content: fa-content($fa-var-studiovinari); }
.#{$fa-css-prefix}-stumbleupon:before { content: fa-content($fa-var-stumbleupon); }
.#{$fa-css-prefix}-stumbleupon-circle:before { content: fa-content($fa-var-stumbleupon-circle); }
.#{$fa-css-prefix}-subscript:before { content: fa-content($fa-var-subscript); }
.#{$fa-css-prefix}-subway:before { content: fa-content($fa-var-subway); }
.#{$fa-css-prefix}-suitcase:before { content: fa-content($fa-var-suitcase); }
.#{$fa-css-prefix}-sun:before { content: fa-content($fa-var-sun); }
.#{$fa-css-prefix}-superpowers:before { content: fa-content($fa-var-superpowers); }
.#{$fa-css-prefix}-superscript:before { content: fa-content($fa-var-superscript); }
.#{$fa-css-prefix}-supple:before { content: fa-content($fa-var-supple); }
.#{$fa-css-prefix}-sync:before { content: fa-content($fa-var-sync); }
.#{$fa-css-prefix}-sync-alt:before { content: fa-content($fa-var-sync-alt); }
.#{$fa-css-prefix}-syringe:before { content: fa-content($fa-var-syringe); }
.#{$fa-css-prefix}-table:before { content: fa-content($fa-var-table); }
.#{$fa-css-prefix}-table-tennis:before { content: fa-content($fa-var-table-tennis); }
.#{$fa-css-prefix}-tablet:before { content: fa-content($fa-var-tablet); }
.#{$fa-css-prefix}-tablet-alt:before { content: fa-content($fa-var-tablet-alt); }
.#{$fa-css-prefix}-tablets:before { content: fa-content($fa-var-tablets); }
.#{$fa-css-prefix}-tachometer-alt:before { content: fa-content($fa-var-tachometer-alt); }
.#{$fa-css-prefix}-tag:before { content: fa-content($fa-var-tag); }
.#{$fa-css-prefix}-tags:before { content: fa-content($fa-var-tags); }
.#{$fa-css-prefix}-tape:before { content: fa-content($fa-var-tape); }
.#{$fa-css-prefix}-tasks:before { content: fa-content($fa-var-tasks); }
.#{$fa-css-prefix}-taxi:before { content: fa-content($fa-var-taxi); }
.#{$fa-css-prefix}-telegram:before { content: fa-content($fa-var-telegram); }
.#{$fa-css-prefix}-telegram-plane:before { content: fa-content($fa-var-telegram-plane); }
.#{$fa-css-prefix}-tencent-weibo:before { content: fa-content($fa-var-tencent-weibo); }
.#{$fa-css-prefix}-terminal:before { content: fa-content($fa-var-terminal); }
.#{$fa-css-prefix}-text-height:before { content: fa-content($fa-var-text-height); }
.#{$fa-css-prefix}-text-width:before { content: fa-content($fa-var-text-width); }
.#{$fa-css-prefix}-th:before { content: fa-content($fa-var-th); }
.#{$fa-css-prefix}-th-large:before { content: fa-content($fa-var-th-large); }
.#{$fa-css-prefix}-th-list:before { content: fa-content($fa-var-th-list); }
.#{$fa-css-prefix}-themeisle:before { content: fa-content($fa-var-themeisle); }
.#{$fa-css-prefix}-thermometer:before { content: fa-content($fa-var-thermometer); }
.#{$fa-css-prefix}-thermometer-empty:before { content: fa-content($fa-var-thermometer-empty); }
.#{$fa-css-prefix}-thermometer-full:before { content: fa-content($fa-var-thermometer-full); }
.#{$fa-css-prefix}-thermometer-half:before { content: fa-content($fa-var-thermometer-half); }
.#{$fa-css-prefix}-thermometer-quarter:before { content: fa-content($fa-var-thermometer-quarter); }
.#{$fa-css-prefix}-thermometer-three-quarters:before { content: fa-content($fa-var-thermometer-three-quarters); }
.#{$fa-css-prefix}-thumbs-down:before { content: fa-content($fa-var-thumbs-down); }
.#{$fa-css-prefix}-thumbs-up:before { content: fa-content($fa-var-thumbs-up); }
.#{$fa-css-prefix}-thumbtack:before { content: fa-content($fa-var-thumbtack); }
.#{$fa-css-prefix}-ticket-alt:before { content: fa-content($fa-var-ticket-alt); }
.#{$fa-css-prefix}-times:before { content: fa-content($fa-var-times); }
.#{$fa-css-prefix}-times-circle:before { content: fa-content($fa-var-times-circle); }
.#{$fa-css-prefix}-tint:before { content: fa-content($fa-var-tint); }
.#{$fa-css-prefix}-toggle-off:before { content: fa-content($fa-var-toggle-off); }
.#{$fa-css-prefix}-toggle-on:before { content: fa-content($fa-var-toggle-on); }
.#{$fa-css-prefix}-trademark:before { content: fa-content($fa-var-trademark); }
.#{$fa-css-prefix}-train:before { content: fa-content($fa-var-train); }
.#{$fa-css-prefix}-transgender:before { content: fa-content($fa-var-transgender); }
.#{$fa-css-prefix}-transgender-alt:before { content: fa-content($fa-var-transgender-alt); }
.#{$fa-css-prefix}-trash:before { content: fa-content($fa-var-trash); }
.#{$fa-css-prefix}-trash-alt:before { content: fa-content($fa-var-trash-alt); }
.#{$fa-css-prefix}-tree:before { content: fa-content($fa-var-tree); }
.#{$fa-css-prefix}-trello:before { content: fa-content($fa-var-trello); }
.#{$fa-css-prefix}-tripadvisor:before { content: fa-content($fa-var-tripadvisor); }
.#{$fa-css-prefix}-trophy:before { content: fa-content($fa-var-trophy); }
.#{$fa-css-prefix}-truck:before { content: fa-content($fa-var-truck); }
.#{$fa-css-prefix}-truck-loading:before { content: fa-content($fa-var-truck-loading); }
.#{$fa-css-prefix}-truck-moving:before { content: fa-content($fa-var-truck-moving); }
.#{$fa-css-prefix}-tty:before { content: fa-content($fa-var-tty); }
.#{$fa-css-prefix}-tumblr:before { content: fa-content($fa-var-tumblr); }
.#{$fa-css-prefix}-tumblr-square:before { content: fa-content($fa-var-tumblr-square); }
.#{$fa-css-prefix}-tv:before { content: fa-content($fa-var-tv); }
.#{$fa-css-prefix}-twitch:before { content: fa-content($fa-var-twitch); }
.#{$fa-css-prefix}-twitter:before { content: fa-content($fa-var-twitter); }
.#{$fa-css-prefix}-twitter-square:before { content: fa-content($fa-var-twitter-square); }
.#{$fa-css-prefix}-typo3:before { content: fa-content($fa-var-typo3); }
.#{$fa-css-prefix}-uber:before { content: fa-content($fa-var-uber); }
.#{$fa-css-prefix}-uikit:before { content: fa-content($fa-var-uikit); }
.#{$fa-css-prefix}-umbrella:before { content: fa-content($fa-var-umbrella); }
.#{$fa-css-prefix}-underline:before { content: fa-content($fa-var-underline); }
.#{$fa-css-prefix}-undo:before { content: fa-content($fa-var-undo); }
.#{$fa-css-prefix}-undo-alt:before { content: fa-content($fa-var-undo-alt); }
.#{$fa-css-prefix}-uniregistry:before { content: fa-content($fa-var-uniregistry); }
.#{$fa-css-prefix}-universal-access:before { content: fa-content($fa-var-universal-access); }
.#{$fa-css-prefix}-university:before { content: fa-content($fa-var-university); }
.#{$fa-css-prefix}-unlink:before { content: fa-content($fa-var-unlink); }
.#{$fa-css-prefix}-unlock:before { content: fa-content($fa-var-unlock); }
.#{$fa-css-prefix}-unlock-alt:before { content: fa-content($fa-var-unlock-alt); }
.#{$fa-css-prefix}-untappd:before { content: fa-content($fa-var-untappd); }
.#{$fa-css-prefix}-upload:before { content: fa-content($fa-var-upload); }
.#{$fa-css-prefix}-usb:before { content: fa-content($fa-var-usb); }
.#{$fa-css-prefix}-user:before { content: fa-content($fa-var-user); }
.#{$fa-css-prefix}-user-circle:before { content: fa-content($fa-var-user-circle); }
.#{$fa-css-prefix}-user-md:before { content: fa-content($fa-var-user-md); }
.#{$fa-css-prefix}-user-plus:before { content: fa-content($fa-var-user-plus); }
.#{$fa-css-prefix}-user-secret:before { content: fa-content($fa-var-user-secret); }
.#{$fa-css-prefix}-user-times:before { content: fa-content($fa-var-user-times); }
.#{$fa-css-prefix}-users:before { content: fa-content($fa-var-users); }
.#{$fa-css-prefix}-ussunnah:before { content: fa-content($fa-var-ussunnah); }
.#{$fa-css-prefix}-utensil-spoon:before { content: fa-content($fa-var-utensil-spoon); }
.#{$fa-css-prefix}-utensils:before { content: fa-content($fa-var-utensils); }
.#{$fa-css-prefix}-vaadin:before { content: fa-content($fa-var-vaadin); }
.#{$fa-css-prefix}-venus:before { content: fa-content($fa-var-venus); }
.#{$fa-css-prefix}-venus-double:before { content: fa-content($fa-var-venus-double); }
.#{$fa-css-prefix}-venus-mars:before { content: fa-content($fa-var-venus-mars); }
.#{$fa-css-prefix}-viacoin:before { content: fa-content($fa-var-viacoin); }
.#{$fa-css-prefix}-viadeo:before { content: fa-content($fa-var-viadeo); }
.#{$fa-css-prefix}-viadeo-square:before { content: fa-content($fa-var-viadeo-square); }
.#{$fa-css-prefix}-vial:before { content: fa-content($fa-var-vial); }
.#{$fa-css-prefix}-vials:before { content: fa-content($fa-var-vials); }
.#{$fa-css-prefix}-viber:before { content: fa-content($fa-var-viber); }
.#{$fa-css-prefix}-video:before { content: fa-content($fa-var-video); }
.#{$fa-css-prefix}-video-slash:before { content: fa-content($fa-var-video-slash); }
.#{$fa-css-prefix}-vimeo:before { content: fa-content($fa-var-vimeo); }
.#{$fa-css-prefix}-vimeo-square:before { content: fa-content($fa-var-vimeo-square); }
.#{$fa-css-prefix}-vimeo-v:before { content: fa-content($fa-var-vimeo-v); }
.#{$fa-css-prefix}-vine:before { content: fa-content($fa-var-vine); }
.#{$fa-css-prefix}-vk:before { content: fa-content($fa-var-vk); }
.#{$fa-css-prefix}-vnv:before { content: fa-content($fa-var-vnv); }
.#{$fa-css-prefix}-volleyball-ball:before { content: fa-content($fa-var-volleyball-ball); }
.#{$fa-css-prefix}-volume-down:before { content: fa-content($fa-var-volume-down); }
.#{$fa-css-prefix}-volume-off:before { content: fa-content($fa-var-volume-off); }
.#{$fa-css-prefix}-volume-up:before { content: fa-content($fa-var-volume-up); }
.#{$fa-css-prefix}-vuejs:before { content: fa-content($fa-var-vuejs); }
.#{$fa-css-prefix}-warehouse:before { content: fa-content($fa-var-warehouse); }
.#{$fa-css-prefix}-weibo:before { content: fa-content($fa-var-weibo); }
.#{$fa-css-prefix}-weight:before { content: fa-content($fa-var-weight); }
.#{$fa-css-prefix}-weixin:before { content: fa-content($fa-var-weixin); }
.#{$fa-css-prefix}-whatsapp:before { content: fa-content($fa-var-whatsapp); }
.#{$fa-css-prefix}-whatsapp-square:before { content: fa-content($fa-var-whatsapp-square); }
.#{$fa-css-prefix}-wheelchair:before { content: fa-content($fa-var-wheelchair); }
.#{$fa-css-prefix}-whmcs:before { content: fa-content($fa-var-whmcs); }
.#{$fa-css-prefix}-wifi:before { content: fa-content($fa-var-wifi); }
.#{$fa-css-prefix}-wikipedia-w:before { content: fa-content($fa-var-wikipedia-w); }
.#{$fa-css-prefix}-window-close:before { content: fa-content($fa-var-window-close); }
.#{$fa-css-prefix}-window-maximize:before { content: fa-content($fa-var-window-maximize); }
.#{$fa-css-prefix}-window-minimize:before { content: fa-content($fa-var-window-minimize); }
.#{$fa-css-prefix}-window-restore:before { content: fa-content($fa-var-window-restore); }
.#{$fa-css-prefix}-windows:before { content: fa-content($fa-var-windows); }
.#{$fa-css-prefix}-wine-glass:before { content: fa-content($fa-var-wine-glass); }
.#{$fa-css-prefix}-won-sign:before { content: fa-content($fa-var-won-sign); }
.#{$fa-css-prefix}-wordpress:before { content: fa-content($fa-var-wordpress); }
.#{$fa-css-prefix}-wordpress-simple:before { content: fa-content($fa-var-wordpress-simple); }
.#{$fa-css-prefix}-wpbeginner:before { content: fa-content($fa-var-wpbeginner); }
.#{$fa-css-prefix}-wpexplorer:before { content: fa-content($fa-var-wpexplorer); }
.#{$fa-css-prefix}-wpforms:before { content: fa-content($fa-var-wpforms); }
.#{$fa-css-prefix}-wrench:before { content: fa-content($fa-var-wrench); }
.#{$fa-css-prefix}-x-ray:before { content: fa-content($fa-var-x-ray); }
.#{$fa-css-prefix}-xbox:before { content: fa-content($fa-var-xbox); }
.#{$fa-css-prefix}-xing:before { content: fa-content($fa-var-xing); }
.#{$fa-css-prefix}-xing-square:before { content: fa-content($fa-var-xing-square); }
.#{$fa-css-prefix}-y-combinator:before { content: fa-content($fa-var-y-combinator); }
.#{$fa-css-prefix}-yahoo:before { content: fa-content($fa-var-yahoo); }
.#{$fa-css-prefix}-yandex:before { content: fa-content($fa-var-yandex); }
.#{$fa-css-prefix}-yandex-international:before { content: fa-content($fa-var-yandex-international); }
.#{$fa-css-prefix}-yelp:before { content: fa-content($fa-var-yelp); }
.#{$fa-css-prefix}-yen-sign:before { content: fa-content($fa-var-yen-sign); }
.#{$fa-css-prefix}-yoast:before { content: fa-content($fa-var-yoast); }
.#{$fa-css-prefix}-youtube:before { content: fa-content($fa-var-youtube); }
.#{$fa-css-prefix}-youtube-square:before { content: fa-content($fa-var-youtube-square); }
