#form-ajax-alerta{
	position: fixed;
	top: 100%;
	left: 0;
	width: 100%;
	background: #F9EDB8; 
	line-height: 1.1;
	padding: 18px 0;
	text-align: center;
	border-top: 1px solid #EDC967;
	z-index: 1000;
	cursor: pointer;
	&.ativo {
		top: unset;
		bottom: 0;
	}
	&.sucesso {
		background-color: #BEFAB9;
		border-color: #71ED66;
	}
	&.erro {
		background-color: #F9B8B8;
		border-color: #ED6767;
	}
	.progresso {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background: rgba(#000000, 0.1);
	}
}