.el-home-slides{
	min-height: 200px;
	li{
		display: block;
		overflow: hidden;
		a{
			display: block;
			position: relative;
			img{
				display: block;
				position: relative;
				max-width: 100%;
				margin: 0 auto;
				height: auto;
				@include desktop {
					left: 50%;
					max-width: none;
					margin: 0;
					width: 1640px;
					margin-left: -820px;
				}
				@include desktop-hd {
					width: 1920px;
					margin-left: -960px;
				}
			}
		}
	}
	.slick-arrow{
		position: absolute;
		top: 50%;
		width: 48px;
		height: 48px;
		line-height: 48px;
		margin-top: -24px;
		font-size: 20px;
		text-align: center;
		color: #ffffff;
		background: rgba(0, 0, 0, 0.25);
		z-index: 1;
		cursor: pointer;
		&:hover{
			background: rgba(0, 0, 0, 0.75);
		}
		&.slick-prev{
			left: 0;
		}
		&.slick-next{
			right: 0;
		}
	}
	.slick-dots{
		position: absolute;
		width: 100%;
		bottom: 5px;
		text-align: center;
		@include desktop {
			bottom: 60px;
		}
		li{
			display: inline-block;
			width: 10px;
			height: 10px;
			margin: 0 8px;
			button{
				display: block;
				width: 100%;
				height: 100%;
				background: #aaaaaa;
				border-radius: 50%;
				padding: 0;
				margin: 0;
				text-indent: -9999px;
				overflow: hidden;
			}
			&.slick-active button{
				background-color: #ffffff;
			}
		}
	}
}