.el-itens-destaque{
	padding: 30px;
	
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	border-top: solid 1px $color_destaque2;

	@include tablet {
		padding: 120px 0 60px 0;
	}
	
	.introducao {
		.v1 {
			margin-bottom: 20px;
		}
		.texto-editavel {
			margin-bottom: 20px;
		}
	}
	

	.itens {
		@include desktop {
			display: flex;
			justify-content: space-around;
			align-items: center;
		}
		
		.lista {
			@include desktop {
				display: flex;
				justify-content: space-around;
				align-items: center;
			}
			
			li {
				
				&:first-child {
					margin-top: 45px;
					margin-bottom: 60px;
				}
				
				@include desktop {
					margin: 0 45px;
				}
				
				.box {
					@include desktop {
						border: solid 1px $color_destaque2;
						border-radius: 50%;					
						text-align: center;
						height: 350px;
						width: 350px;
						padding-top: 60px;
					}
				}

				
				.icone{
					text-align: center;
					
					img{
						display: inline-block;
						vertical-align: middle;
						max-width: 80%;
						height: auto;
					}
				}

				.info {
					flex-grow: 1;
					text-align: center;
					width: 250px;
					margin: 0 auto;
					
					.titulo {
						font-weight: normal;
						line-height: 1.2;
						color: $color_destaque2;
						font-size: 30px;
						margin-bottom: 10px;
					}
					
					.texto {
						line-height: 1.2;
						@include desktop {
						}
					}
				}
			}
		}
	}

	&.com-imagem{
		.itens{
			.lista{
				@include desktop {
					width: 46%;
				}
			}
		}
	}
	
	&.imagem-left{
		.itens{
			.img{
				@include desktop {
					float: left;
					width: 50%;
					margin-right: 4%;

					order: 0;
				}
			}
		}
	}
	&.imagem-right{
		.itens{
			.img{
				@include desktop {
					float: right;
					width: 50%;
					margin-left: 4%;

					order: 1;
				}
			}
		}
	}

	&.icone-left{
		.itens{
			.lista{
				li{
					flex-wrap: nowrap;
					.icone{
						order: 0;
						margin-right: 20px;
						margin-bottom: 0;
					}
					.info{
						order: 1;
						width: auto;
						text-align: left;
					}
				}
			}
		}
	}
	&.icone-right{
		.itens{
			.lista{
				li{
					flex-wrap: nowrap;
					.icone{
						order: 1;
						margin-left: 20px;
						margin-bottom: 0;
					}
					.info{
						order: 0;
						width: auto;
						text-align: right;
					}
				}
			}
		}
	}

	&.itens-h-right{
		.itens{
			.lista{
				justify-content: flex-end;
			}
		}
	}
	&.itens-h-left{
		.itens{
			.lista{
				justify-content: flex-start;
			}
		}
	}
	&.itens-h-center{
		.itens{
			.lista{
				justify-content: center;
			}
		}
	}

	&.itens-v-top{
		.itens{
			.lista{
				align-items: flex-start;
			}
		}
	}
	&.itens-v-base{
		.itens{
			.lista{
				align-items: flex-end;
			}
		}
	}
	&.itens-v-center{
		.itens{
			.lista{
				align-items: center;
			}
		}
	}

	&.itens-maximo-2{
		.itens{
			.lista{
				li{
					@include tablet {
						width: 49%;
						&:nth-child(n) { margin-left: 2% }
						&:nth-child(2n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-3{
		.itens{
			.lista{
				li{
					@include tablet {
						width: 49%;
						&:nth-child(n) { margin-left: 2% }
						&:nth-child(2n+1) { margin-left: 0 }
					}
					@include desktop {
						width: 32%;
						&:nth-child(n) { margin-left: 2% }
						&:nth-child(3n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-4{
		.itens{
			.lista{
				li{
					@include tablet {
						width: 49%;
						&:nth-child(n) { margin-left: 2% }
						&:nth-child(2n+1) { margin-left: 0 }
					}
					@include desktop {
						width: 23.5%;
						&:nth-child(n) { margin-left: 2% }
						&:nth-child(4n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-5{
		.itens{
			.lista{
				li{
					@include tablet {
						width: 49%;
						&:nth-child(n) { margin-left: 2% }
						&:nth-child(2n+1) { margin-left: 0 }
					}
					@include desktop {
						width: 18.4%;
						&:nth-child(n) { margin-left: 2% }
						&:nth-child(5n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
	&.itens-maximo-6{
		.itens{
			.lista{
				li{
					@include tablet {
						width: 49%;
						&:nth-child(n) { margin-left: 2% }
						&:nth-child(2n+1) { margin-left: 0 }
					}
					@include desktop {
						width: 15%;
						&:nth-child(n) { margin-left: 2% }
						&:nth-child(6n+1) { margin-left: 0 }
					}
				}
			}
		}
	}
}