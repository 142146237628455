.paginacao{
	margin-bottom: 20px;
	ul{
		text-align: center;
		li{
			display: inline-block;
			a{
				display: block;
				padding: 10px 15px;
				&[href]{
					font-weight: bold;
					&:hover{
						background: rgba(0, 0, 0, 0.1);
					}
				}
			}
		}
	}
}