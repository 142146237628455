.el-imagem-container {
	display: none;
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin: 0 auto;

	@include desktop {
		max-width: 950px;
		margin-top: -180px;
		display: block;
	}
	
	img {
		width: 100%;
		border-radius: 10px;
	}
}