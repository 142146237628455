.el-galeria-arquivos{
	padding: 30px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include tablet {
		padding: 60px 0;
	}
	.introducao{
		.v1{
			margin-bottom: 20px;
		}
		.texto-editavel{
			margin-bottom: 20px;
		}
	}
	.itens{
		@include clf;
		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			li{
				float: left;
				width: 100%;
				margin-bottom: 10px;
				@include phablet {
					width: 49%;
					&:nth-child(n) { margin-left: 2% }
					&:nth-child(2n+1) { margin-left: 0 }
				}
				@include tablet {
					width: 32%;
					&:nth-child(n) { margin-left: 2% }
					&:nth-child(3n+1) { margin-left: 0 }
				}
				a{
					position: relative;
					display: block;
					padding: 10px 10px 10px 40px;
					background-color: #f1f1f1;
					min-height: 20px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					&:hover{
						color: $color_destaque;
					}
					i{
						position: absolute;
						top: 50%;
						left: 10px;
						font-size: 20px;
						line-height: 20px;
						margin-top: -10px;
					}
				}
			}
		}
	}
}