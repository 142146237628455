/* Media Queries - ATENÇÃO: Os media queries são feitos sob o paradigma 'Mobile-First'
–––––––––––––––––––––––––––––––––––––––––––––––––– */
$mobile:			400;
$phablet:			550;
$tablet:			768;
$desktop:			1024;
$desktop-hd:		1200;

@mixin mobile		{ @media screen and (min-width: #{$mobile}px) { @content; } }
@mixin phablet		{ @media screen and (min-width: #{$phablet}px) { @content; } }
@mixin tablet		{ @media screen and (min-width: #{$tablet}px) { @content; } }
@mixin desktop		{ @media screen and (min-width: #{$desktop}px) { @content; } }
@mixin desktop-hd	{ @media screen and (min-width: #{$desktop-hd}px) { @content; } }


/* Media Queries Personalizáveis
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@mixin mq-min($w_min) { @media screen and (min-width: #{$w_min}px) { @content; } }
@mixin mq-max($w_max) { @media screen and (max-width: #{$w_max}px) { @content; } }
@mixin mq-min-max($w_min, $w_max) { @media screen and (min-width: #{$w_min}px) and (max-width: #{$w_max}px) { @content; } }


/* Media Querie Print
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@mixin print { @media print { @content; } }


/* Mixins Utilitários
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@mixin animado($t: 0.2s){
	transition-delay: 0;
	transition-duration: $t;
	transition-property: all;
	transition-timing-function: ease-in-out;
}
@mixin caixa-texto($font-size, $line-height, $linhas, $fixo: true) {
	font-size: $font-size + px;
	line-height: $line-height;
	@if $fixo {
		height: $font-size * $line-height * $linhas + px;
	} @else {
		max-height: $font-size * $line-height * $linhas + px;
	}
	overflow: hidden;
}
@mixin clf{
	*zoom: 1;
	&:before,
	&:after{
		content: "";
		display: table;
	}
	&:after{
		clear: both;
	}
}
@mixin bsf($bs : border-box){
	@include box-sizing($bs);
	*, *:before, *:after { @include box-sizing($bs) }
}
@mixin img-box{
	img{
		display: block;
		margin: 0 auto;
		max-width: 100%;
		height: auto;
	}
}
@mixin text-ellipsis{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@mixin texto-editavel{
	line-height: 1.2;
	@include clf;
	p{
		margin-bottom: 10px;
	}
	ol, ul{
		margin-bottom: 10px;
		ol, ul{
			padding-left: 15px;
		}
		li{
			list-style-position: inside;
		}
	}
	ul li{
		list-style-type: disc ;
	}
	ol li{
		list-style-type: decimal;
	}
	strong{
		font-weight: bold;
	}
	h1,h2,h3,h4,h5,h6{
		font-weight: bold;
		margin-bottom: 10px;
		padding-top: 20px;
		&:first-child{
			padding-top: 0;
		}
	}
	h1{
		font-size: 26px;
	}
	h2{
		font-size: 24px;
	}
	h3{
		font-size: 22px;
	}
	h4{
		font-size: 20px;
	}
	h5{
		font-size: 18px;
	}
	h6{
		font-size: 16px;
	}
	table{
		max-width: 100%;
		tr{
			th{
				font-weight: bold;
			}
			th, td{
				padding: 2px 5px;
				text-align: left;
				border: 1px solid #cccccc;
			}
		}
	}
	img{
		max-width: 100%;
		height: auto;
	}
}
@mixin youtube-box{
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	iframe,
	object,
	embed{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
@mixin chartjs-box{
	position: relative;
	max-width: 100%;
	margin-bottom: 30px;
}
@function valor-fluido($valor, $vw_referencia){
	@return calc(#{$valor} / #{$vw_referencia} * 100vw);
}