#categorias-bottom {
	
	padding-top: 40px;
	height: 250px;
	background: url('../imagens/banner-categoria-rodape.jpg') center center;
	background-size: cover;
	background-repeat: no-repeat;

	@include desktop {
		height: 400px;
	}

	.negrito {
		font-size: 25px;
		@include desktop {
			font-size: 40px;
		}
		font-weight: bold;
		color: #FFF;
		display: inline-block;

		&:nth-child(1) {
			display: block;
			@include desktop {
				display: block;
			}
		}
		&:nth-child(2) {
			display: block;
			@include desktop {
				display: inline-block;
			}
		}		
	}

	ul {
		display: none;
		@include desktop { display: block; margin-top: 30px; }

		li {
			display: inline-block;
			margin-bottom: 3px;
			a {
				background: $color_destaque2;
				padding: 5px 10px;
				color: #fff;
				display: block;

				&:hover {
					background: #c16710;
				}
			}
		}
	}
}
.whatsapp-flutuante{
	position: fixed;
	bottom: 50px;
	right: 30px;
	z-index: 99999;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	text-align: center;
	@include mq-max(1023){
		bottom: 100px;
		right: 20px;
	}
	a{
		background-color: #4dc247 !important;
		box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
		width: 100%;
		height: 100%;
		cursor: pointer;
		display: block;
		display: flex;
		align-items: center;
		justify-content: center;
		svg{
			width: 28px;
			height: auto;
			path{
				fill: #FFF;
			}
		}
	}
}
footer{
	font-size: 14px !important;

	.copyright{
		padding: 10px 0;
		background: #f1f1f1;
		font-size: 13px;
		text-align: center;
		line-height: 1.5;
		a{
			&:hover{
				color: $color_destaque;
			}
		}
		.left{
			@include desktop {
				float: left;
			}
		}
		.right{
			@include desktop {
				float: right;
			}
		}
	}

	// CSS do rodapé verde
	.barra-verde {
		background: url('../imagens/background-rodape.jpg');
		padding: 30px 0;
		color: #FFF;
		line-height: 1.5;

		.container {
			text-align: center;

			@include desktop {
				text-align: left;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}

			.logo {
				flex-grow: 1;
			}

			.menu {
				flex-grow: 2;
				margin: 10px 0;
				b {
					color: $color_destaque;
					font-weight: bold;
				}				
				ul {
					li {
						a {
							color: #FFF;

							&:hover {
								color: $color_destaque;
							}
						}
					}
				}
			}

			.endereco {
				flex-grow: 2;
				margin: 10px 0;

				b {
					color: $color_destaque;
					font-weight: bold;
				}				
				strong {
					font-weight: bold;
				}
			}

			.ubs {
				flex-grow: 1;
				margin: 10px 0;
				
				b {
					color: $color_destaque;
					font-weight: bold;
				}	

				.social {
					@include desktop {
						text-align: center;
						border-top: solid 1px;
						margin-top: 15px;						
					}
					ul {
						li {
							display: inline-block;
							margin: 10px 2px;
							font-size: 20px;
							background: #FFF;
							border-radius: 50%;
							line-height: 1.6;

							a {
								display: block;
								color: #0d5f04;
								width: 32px;
								height: 32px;								
							}
						}
					}
				}			
			}

			.parceiro {
				flex-grow: 2;
				margin: 10px 0;

				@include desktop {
					text-align: right;
				}
			}
		}

		.copyright-paso {
			text-align: center;
			font-size: 11px;
		}
	}
}