.el-texto-audio-spotfy {
	padding: 30px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include tablet {
		padding: 60px 0;
	}
	.img{
		background: rgba(0, 0, 0, 0.05);
		margin-bottom: 20px;
		img{
			display: block;
			margin: 0 auto;
			max-width: 100%;
			height: auto;
		}
		span{
			display: block;
			clear: both;
			padding: 10px;
			text-align: center;
			word-break: break-word;
			font-size: 12px;
		}
	}
	.texto{
		.v1{
			margin-bottom: 20px;
		}

		.iframe{
			margin-bottom: 20px;
		}
	}
	
	&.align{
		.wrapper-img-texto{
			@include desktop {
				display: flex;
				justify-content: space-between;
			}
		}
		&.topo .wrapper-img-texto{
			@include desktop {
				align-items: flex-start;
			}
		}
		&.meio .wrapper-img-texto{
			@include desktop {
				align-items: center;
			}
		}
		&.base .wrapper-img-texto{
			@include desktop {
				align-items: flex-end;
			}
		}
		.texto{
			@include desktop {
				width: 46%;
			}

		}
	}

	&.imagem-left{
		.img{
			@include desktop {
				float: left;
				width: 50%;
				margin-right: 4%;

				order: 0;
			}
		}
	}
	&.imagem-right{
		.img{
			@include desktop {
				float: right;
				width: 50%;
				margin-left: 4%;

				order: 1;
			}
		}
	}

}