.el-home-botao-acao {
	margin: 60px 0;
	text-align: center;
	a {
		color: #fff;
		background: $color_destaque2;
		padding: 5px 25px;
		border-radius: 50px;
		font-weight: bold;
		font-size: 20px;

		&:hover {
			background: #c16710;
		}
	}
}