.el-formulario-contato {
	padding: 30px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	
	@include tablet {
		padding: 60px 0;
	}
	
	.introducao{
		.v1{
			margin-bottom: 20px;
		}
		.texto-editavel{
			margin-bottom: 20px;
		}
	}
	
	.form {
		.g-recaptcha{
			margin-bottom: 10px;
		}
		input[type="submit"] {
			width: 100%;
			@include desktop {
				width: auto;
			}
		}
		textarea {
			height: 147px;
		}

	}
}