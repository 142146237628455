.el-texto-livre {
	padding: 30px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	
	@include tablet {
		padding: 60px 0;
		position: relative;
	}
	.img{
		background: rgba(0, 0, 0, 0.05);
		margin-bottom: 20px;
		img{
			display: block;
			margin: 0 auto;
			max-width: 100%;
			height: auto;
		}
		span{
			display: block;
			clear: both;
			padding: 10px;
			text-align: center;
			word-break: break-word;
			font-size: 12px;
		}
	}
	.texto{
		.v1{
			margin-bottom: 20px;
		}
	}
}