.el-home-institucional{
	padding: 30px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include tablet {
		padding: 60px 0;
	}
	.wrapper-img-texto{
		@include desktop {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.img{
			background: rgba(0, 0, 0, 0.05);
			margin-bottom: 20px;
			@include desktop {
				margin-bottom: 0;
			}
			img{
				display: block;
				margin: 0 auto;
				max-width: 100%;
				height: auto;
			}
			span{
				display: block;
				clear: both;
				padding: 10px;
				text-align: center;
				word-break: break-word;
				font-size: 12px;
			}
		}
		.texto{
			@include desktop {
				width: 46%;
			}
			.v1{
				margin-bottom: 20px;
			}
			.texto-editavel{
				margin-bottom: 20px;
			}
			.btn-mais{
				display: inline-block;
				padding: 10px 20px;
				color: #ffffff;
				background-color: $color_destaque;
				&:hover{
					background-color: darken($color_destaque, 10%);
				}
			}
		}
		
	}

	&.imagem-left{
		.img{
			@include desktop {
				float: left;
				width: 50%;
				margin-right: 4%;

				order: 0;
			}
		}
	}
	&.imagem-right{
		.img{
			@include desktop {
				float: right;
				width: 50%;
				margin-left: 4%;

				order: 1;
			}
		}
	}
}