.erro-404{
	text-align: center;
	padding: 100px 0;
	@include tablet {
		padding: 150px 0;
	}
	h1{
		font-size: 25px;
		font-weight: bold;
		line-height: 1.2;
		margin-bottom: 20px;
		@include tablet {
			font-size: 40px;
		}
	}
	p{
		font-size: 20px;
		line-height: 1.2;
		@include tablet {
			font-size: 30px;
		}
	}
}