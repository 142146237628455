.el-galeria-imagens{
	padding: 30px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include tablet {
		padding: 60px 0;
	}
	.introducao{
		.v1{
			margin-bottom: 20px;
		}
		.texto-editavel{
			margin-bottom: 20px;
		}
	}
	.itens{
		@include clf;
		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			li{
				float: left;
				width: 100%;
				@include phablet {
					width: 50%;
				}
				@include tablet {
					width: 33.333333%;
				}
				a{
					position: relative;
					display: block;
					.hover{
						position: absolute;
						display: flex;
						align-items: center;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						padding: 10px;
						background-color: rgba(0, 0, 0, 0.75);
						opacity: 0;
						.legenda{
							display: block;
							width: 100%;
							color: #ffffff;
							text-align: center;
							line-height: 1.2;
						}
					}
					&:hover .hover{
						opacity: 1;
					}
					img{
						display: block;
						max-width: 100%;
						height: auto;
					}
				}
			}
		}
	}

	&.itens-maximo-3{
		.itens{
			ul{
				li{
					@include desktop {
						width: 33.333333%;
					}
				}
			}
		}
	}
	&.itens-maximo-4{
		.itens{
			ul{
				li{
					@include desktop {
						width: 25%;
					}
				}
			}
		}
	}
	&.itens-maximo-5{
		.itens{
			ul{
				li{
					@include desktop {
						width: 20%;
					}
				}
			}
		}
	}
	&.itens-maximo-6{
		.itens{
			ul{
				li{
					@include desktop {
						width: 16.666667%;
					}
				}
			}
		}
	}
}