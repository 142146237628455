.breadcrumb{
	padding: 15px 0;
	background-color: #f1f1f1;
	ul{
		@include clf;
		li{
			float: left;
			&:after{
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				content: "\f054";
				padding: 0 8px;
			}
			&:last-child:after{
				display: none;
			}
			a{
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}