.el-formulario-comentario{
	padding: 30px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include tablet {
		padding: 60px 0;
	}
	.introducao{
		.v1{
			margin-bottom: 20px;
		}
	}
	.form{
		.g-recaptcha{
			margin-bottom: 10px;
		}
	}
	.comentarios{
		padding: 15px;
		background: #f1f1f1;
		.v1{
			margin-bottom: 20px;
		}
		.lista{
			@include clf;
			ul{
				li{
					margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
					.nome{
						font-weight: bold;
						margin-bottom: 5px;
						line-height: 1.2;
					}
					.data{
						display: block;
						font-size: 12px;
						margin-bottom: 10px;
					}
					.texto{
						line-height: 1.2;
					}
				}
			}
		}
	}
}