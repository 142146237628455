.menu-categorias {
	ul {
		margin-top: 30px;
		@include desktop {
			display: flex;
			justify-content: space-between;
			margin-top: 15px;
		}

		li {
			margin: 5px 0;
			@include desktop {
				flex: 1;
				margin: 0 1px;

				&:first-child {
					a {
						border-top-left-radius: 10px;
						border-bottom-left-radius: 10px;
					}
				}
				
				&:last-child {
					a {
						border-top-right-radius: 10px;
						border-bottom-right-radius: 10px;
					}
				}				
			}
			
			a {
				text-align: center;
				padding: 10px 0;
				display: block;
				background: #cccccc;
				color: #9d9d9d;

				&:hover {
					background: $color_destaque2;
					color: #FFF;
				}
			}
		}
	}
}

.pagina-topo {
	padding: 40px 0 !important;

	.wrapper {
			
		@include desktop {
			display: flex;
			justify-content: space-between;	
		}

		h1 {
			position: relative;
			&::after {
				content: '';
				position: absolute;
				border-bottom: solid 1px $color_destaque2;
				bottom: -5px;
				left: 0;
				right: 0;

				@include desktop {
					content: '';
					position: absolute;
					border-bottom: solid 1px $color_destaque2;
					left: -500px;
					bottom: -30px;
					right: -180%;
				}			
			}		
		}

		.form-busca {
			display: none;
			@include desktop { display: block; }
			
			input[type="search"] {
				display: inline-block;
				width: 80%;
				padding: 5px 30px;
				border: solid 1px $color_destaque2;
			}
			button {
				width: 10%;
				display: inline-block;
				background: none;
				color: $color_destaque2;
				font-size: 20px;
				padding: 5px 10px;

				&:hover,
				&:focus {
					color: $color_destaque;
				}
			}
		}
	}

}