.el-contato-enviado {
	padding: 30px 0 60px 0;
	@include desktop {
		padding: 60px 0 120px 0;
	}

	text-align: center;

	p {
		font-size: 14px;
		@include desktop {
			font-size: $font-size;
		}
	}
}