.pagina-imagem-fundo {
	width: 100%;
	overflow: hidden;
	img {
		display: block;
		max-width: 640px;
		margin-left: 50%;
		transform: translate(-50%, 0%);

		@include phablet {
			max-width: 880px;
		}
		@include tablet {
			max-width: 1200px;
		}
		@include desktop {
			max-width: 1640px;
		}
		@include desktop-hd {
			max-width: 1920px;
		}
	}
}

.pagina-topo {
	padding: 20px 0;
	overflow: hidden;
	.v1 {
		margin-bottom: 20px;

		.pesquisar {
			position: relative;
			&::after {
				content: '';
				position: absolute;
				border-bottom: solid 1px $color_destaque2;
				bottom: -5px;
				left: 0;
				right: 0;

				@include desktop {
					content: '';
					position: absolute;
					border-bottom: solid 1px $color_destaque2;
					left: -500px;
					bottom: -30px;
					right: -50%;
				}			
			}		
		}
	}

	.compartilhar{
		font-size: 13px;
		color: #888888;
		a{
			display: inline-block;
			width: 20px;
			height: 20px;
			line-height: 20px;
			color: #888888;
			text-align: center;
			cursor: pointer;
			&:hover{
				color: $color_destaque;
			}
		}
	}
}

.pagina-elementos-destaque{
	padding: 15px 0;
	background-color: #f9f9f9;
	@include clf;
	ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		li{
			border-right: 1px solid #cccccc;
			&:last-child{
				border-right: none;
			}
			a{
				display: block;
				padding: 5px 20px;
				line-height: 1.2;
				font-weight: bold;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}