header {
	position: absolute;
	z-index: 10;
	left: 0;
	right: 0;
	top: 0;

	background: $color_destaque2;
	@include desktop {
		background: none;
	}

	.header-wrapper {
		.header-top {
			display: flex;			
			align-items: center;
			padding: 10px 0;
			justify-content: space-between;
			padding-left: 40px;
			position: relative;		
			@include mq-min(1024) {
				padding-left: 0;
			}

			.imagem-flutuante {				
				display: flex;
				align-items: center;
				justify-content: center;
				height: 32px;
				width: 32px;
				border-radius: 16px;
				background-color: #FFF;
				svg {
					height: 100%;
					width: auto;					
				}
				@include mq-min(1024) {
					height: 100px;					
					width: fit-content;
					border-radius: 0;
					background-color: transparent;
					position: absolute;				
					right: 0;
					top: 50%;
					transform: translate(100%,-50%);
				}
			}
	
			.logo {
				.mobile {
					display: block;
					@include desktop {
						display: none;
					}					
				}
				.desktop {
					display: none;
					@include desktop {
						display: block;
					}
				}
				img{
					display: block;
					margin: 0 auto;
					max-width: 100%;
					height: auto;
					width: 120px;

					@include desktop {
						width: 100%;
					}
				}
				@include desktop {
					margin-right: 20px;
				}
			}

			a.btn-nav {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate(0,-50%);
				display: block;
				padding: 10px;
				color: #ffffff;
				cursor: pointer;
				z-index: 9999;
				
				@include desktop {
					display: none !important;
				}
			}

			.nav {
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba(#000000, 0.85);
				z-index: 999;
				visibility: hidden;
				opacity: 0;
				
				@include desktop {
					position: static;
					top: auto;
					right: auto;
					bottom: auto;
					left: auto;
					visibility: visible;
					opacity: 1;
					background: none !important;
				}
				&.active {
					visibility: visible;
					opacity: 1;
				}
				nav {
					width: 100%;
					height: 100%;
				}
				.menu {
					width: 200px;
					height: 100%;

					position: absolute;
					right: 0; 
					top: 0;

					background-color: $color_destaque;

					@include desktop {
						position: relative;
						background-color: transparent;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: center;
						width: auto;
						height: auto;
					}
					li {
						position: relative;
						&:hover > a {
							@include desktop {
								color: #ffffff;
								background-color: $color_destaque;
							}
						}						
						a {
							display: block;
							padding: 8px 20px;
							color: #FFF;
							font-size: 14px;
							border-bottom: solid 1px #dd7512;
							&:hover{
								color: #074202;
								background-color: $color_destaque;
							}
							@include desktop {
								border-bottom: none;
								padding: 10px 15px;
								font-size: 15px !important;
								&[href^="https://pasoita.mercadoshops.com.br"] {
									display: none;
								}
							}
						}
						i {
							&:hover > i {
								@include desktop {
									color: #ffffff;
								}
							}
							display: none;
							@include desktop {
								display: block;
								position: absolute;
								top: 50%;
								right: 10px;
								width: 20px;
								height: 20px;
								line-height: 20px;
								margin-top: -10px;
								font-size: 20px;
							}
						}
						ul {
							background: #f5840a;
							overflow: hidden;
							@include desktop {
								position: absolute;
								top: 100%;
								left: 0;
								width: 200px;
								visibility: hidden;
								opacity: 0;
								z-index: 1;
								background-color: rgba(0,0,0,0.6);
								border-bottom-left-radius: 10px;
								border-bottom-right-radius: 10px;
								border-top-right-radius: 10px;
								box-shadow: 2px 2px 8px -4px rgba(0, 0, 0, 0.5);
							}

							li {
								a {
									padding-left: 45px;
									@include desktop {
										padding-left: 20px;
									}
								}
							}							
						}
						&:hover >ul{
							@include desktop {
								visibility: visible;
								opacity: 1;
							}
						}

						&.barra-idiomas {

							a {
								display: block;
								width: 100%;
								@include desktop {
									margin-right: 20px;
								}
							}
							ul {
								@include desktop {
									width: 80px;
									border-top-right-radius: 0px;
								}
								li {
									a {
										padding-left: 20px;
									}
								}
							}							
						}						
					}

				}
			}
		}
	}
	.menu-acesso{
		display: flex;
		justify-content: flex-end;

		.loja{			
			display: block;
			
			img{
				width: 120px;
			}
			
			@include desktop {
				display: block;
				margin-right: 70px;
			}
		}
	}
}
.whatsapp-flutuante-mobile{
	
	position: fixed;
	bottom: 50px;
	right: 30px;
	z-index: 1;
	display: block;

	img{
		width: 160px;
	}

	a{
		display: block;
	}

	@include desktop {
		display: none;
	}
}